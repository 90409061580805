function EN() {
  return (
    <div>
      <h2>PRIVACY POLICY AND DATA PROTECTION</h2>
      <h3>Welcome!</h3>
      <section>
        <p>
          Below, we share important information so you can understand how we
          handle your personal data. Enjoy reading!
        </p>
        <p>
          STARTA is a company that operates in the business generation in
          the technology field through creative and intelligent solutions,
          aiming to help entrepreneurs materialize ideas and turn them into
          great projects.
        </p>
        <p>
          This Privacy Policy and Data Protection aim to regulate the processing
          of personal data of our Clients, Service Providers, Business Partners,
          and Collaborators.
        </p>
        <p>
          By defining the practices that govern our activities, we commit to
          respecting and observing the General Data Protection Law (Law No.
          13,709/18) and other applicable laws on the subject. We undertake to
          adopt best practices to ensure that your personal data is treated
          appropriately and securely.
        </p>
        <p>
          The provisions of this Privacy Policy apply to
          <b> STARTA TECNOLOGIA DA INFORMAÇÃO LTDA. (STARTA)</b>, a
          private legal entity, registered with CNPJ under No.
          16,713,376/0001-83, headquartered at Rua Candido Silveira, No. 198,
          Room 304, in the municipality of Porto Alegre - RS, ZIP Code
          95,540-010.
        </p>
        <p>
          For a better understanding of the data processing operations carried
          out by <b>STARTA</b>, we recommend that you, the "User,"
          carefully read the terms of this "Policy," including to validate your
          consent regarding the privacy and protection terms of personal data
          that concern you, in accordance with Article 7, item I and Article 33,
          items VIII and IX, of Law No. 13,709/2018 (General Data Protection Law
          - LGPD). usuário deverá se abster de utilizar os nossos serviços.In
          case of disagreement with our Privacy Policy and Terms of Use, the
          user must refrain from using our services.
        </p>
      </section>
      <section>
        <h4>1. DATA PROCESSING</h4>
        <p>
          Personal data is considered any information related to individuals
          that can be identified directly or indirectly through information such
          as name, location data, electronic identification (email, IP,
          smartphones), or other specific characteristics that enable the
          individual identification of the data subject.
        </p>
        <p>
          Any identifiable personal information you provide to us will be used
          only in accordance with the law, including what is necessary to
          provide the product, service, or information requested by you.
        </p>
        <p>
          Your personal data will not be sold or rented, and sharing, if any, is
          limited to our business partners as required in the execution of our
          services or as required by laws and regulations, in which case
          STARTA takes all necessary measures to ensure that subcontractors
          meet applicable legal requirements and provide adequate guarantees for
          data protection.
        </p>
      </section>
      <section>
        <h4>2.WHAT DATA WE COLLECT</h4>
        <p>
          If the "User" is interested in contacting us through this website, the
          following personal data will be requested: name, phone, email.
        </p>
        <p>
          For the performance of our activities and the execution of contracts
          for the development of customizable and non-customizable computer
          programs, technical support, and other computer services, the
          following personal data will be collected, among other information:
          the name of the contractor and/or the legal representative of the
          legal entity, CPF, phone, email, professional registration.
        </p>
        <p>
          For recruitment and personnel selection, the following data will be
          collected initially: name, residential address, email, and other
          information provided by the job candidate in their resume.
        </p>
        <p>
          In cases where the data subject participates in events or webinars
          held by STARTA, registering through online service platforms, it
          is important to note that, in addition to the terms of this Policy,
          the Privacy Policies and Terms of Use of the Event Agent/Promoter
          (Operator) will also apply.
        </p>
        <p>
          Therefore, it is the user's responsibility to read the Privacy Policy
          terms of the Event Agent/Promoter to express their consent regarding
          the processing of their personal data. If necessary, STARTA may
          request other personal data to confirm your identity and
          correspondence with the data entered in the service contract, and this
          request may be made by phone, email, WhatsApp.
        </p>
      </section>
      <section>
        <h4>3.BASIS FOR DATA PROCESSING</h4>
        <p>
          In the exercise of STARTA's activities, the processing of
          personal data falls within the execution and fulfillment of the
          contract; compliance with legal and regulatory obligations; the
          legitimate interest of the company; and in cases where required, with
          the provision of consent by the data subject. The data subjects of
          personal data are guaranteed the rights provided for in Article 18 of
          the General Data Protection Law (Law No. 13,709/18).
        </p>
        <p>
          User's personal data may be used for legal purposes by the data
          controller when required in court or when necessary for filing a
          possible legal action arising from the misuse of this service or
          related services.
        </p>
        <p>
          STARTA may disclose personal data whenever there is a request
          from the Public Authority or Judicial Authority to comply with a legal
          obligation.
        </p>
        <p>
          The data controller may also disclose personal data upon request from
          the public authority or to comply with a legal obligation.
        </p>
      </section>
      <section>
        <h4>4.WHO WE SHARE YOUR DATA WITH</h4>
        <p>
          In the scope of our business activities and commercial relationships,
          information related to the company name, sector, position, department,
          function, and personal data of legal representatives and employees may
          be shared with business partners and collaborators, for the execution
          of a contract or compliance with a legal obligation.
        </p>
        <p>
          Service Providers of STARTA will also have access and may share
          the information and data of the data subjects processed, being guided
          to share personal data exclusively for the necessary purposes and in
          compliance with the General Data Protection Law and other regulations
          and determinations of regulatory bodies.
        </p>
        <p>
          In the event of a sale, merger, or transfer of all or part of our
          business (including in the case of reorganization, dissolution,
          liquidation, or other corporate restructuring), we may disclose your
          personal data to the potential seller or buyer or their
          representatives, provided that appropriate measures are taken to
          ensure the security and confidentiality of the information. Your data
          will NOT be used for other purposes, such as sharing information and
          email lists for marketing.
        </p>
      </section>
      <section>
        <h4>5.INTERNATIONAL DATA TRANSFER</h4>
        <p>
          For the realization and development of innovative projects and
          solutions in the field of technology, STARTA may carry out the
          international transfer of personal data, in accordance with Article
          33, item IX, of Law 13,709/2018, that is, when necessary for the
          execution of a contract or preliminary procedures related to a
          contract of which the data subject is a party, at the request of the
          data subject; for compliance with a legal or regulatory obligation by
          the data controller; or for the regular exercise of rights in a
          judicial, administrative, or arbitral proceeding.
        </p>
        <p>
          In sharing personal data with foreign countries, we adopt the best
          information security practices, seeking to provide our customers and
          data subjects with the degree of confidentiality and protection
          envisaged in the LGPD, and for these cases, the data subject's consent
          is requested.
        </p>
      </section>
      <section>
        <h4>6.HOW YOUR DATA IS COLLECTED</h4>
        <ul>
          <li>Registration data;</li>
          <li>Website;</li>
          <li>Email;</li>
          <li>Messaging and conference apps </li>
          <li>Selection and recruitment of employees;</li>
          <li>Webinars and online events;</li>
          <li>
            Through actions taken by the data subject on the company's social
            media profiles, such as likes, comments, or similar;
          </li>
          <li>
            When publicly available, including, but not limited to, social media
            (such as Facebook, Instagram, and LinkedIn).
          </li>
        </ul>
        <p>
          Some collected personal data is mandatory and necessary for the
          execution and performance of our activities. In case of absence or
          insufficiency of this data, STARTA reserves the right not to
          provide the requested services or information. In each case, data
          subjects will be informed about the mandatory nature of providing this
          personal data and the purpose of its use.
        </p>
        <p>
          We clarify that user interactions with our profiles on social media
          are governed by the privacy policies and terms of use of the
          respective platforms.
        </p>
      </section>
      <section>
        <h4>7.DURATION OF PROCESSING</h4>
        <p>
          The data processing will occur for an indefinite period, ensuring the
          termination of processing in the following situations:
        </p>
        <ul>
          <li>
            Verification that the purpose has been achieved or that the data is
            no longer necessary or relevant to the specific purpose for which it
            was collected;
          </li>
          <li>
            Communication from the data subject, including the exercise of their
            right to revoke consent, safeguarding public interest; or
          </li>
          <li>
            Determination by the National Authority in case of a violation of
            legal provisions.
          </li>
        </ul>
      </section>
      <section>
        <h4>8.STORAGE</h4>
        <p>
          Personal data is stored in secure environments within our IT
          infrastructure and cloud data processing service providers that have
          been previously assessed for data security.
        </p>
        <p>
          In addition to technical and information security measures,
          STARTA adopts administrative measures for risk management with
          Employees and Subcontractors, including the formalization of
          Contracts, Data Protection Regulations, Terms of Responsibility, Codes
          of Conduct, among others, to guide employees on their conduct
          regarding the protection and privacy of personal data.
        </p>
        <p>
          As per item 8, if there is no need for storage, personal data will be
          eliminated after the completion of its processing. However, personal
          data intended for compliance with legal or regulatory obligations by
          STARTA, or for its exclusive use, will be retained, ensuring
          anonymization within technically possible limits.
        </p>
      </section>
      <section>
        <h4>9.RIGHTS OF THE DATA SUBJECT</h4>
        <p>
          STARTA acknowledges that all individuals have the right to the
          protection of personal data and ensures data subjects the exercise of
          rights provided in Law No. 13,709/2018, including the right to obtain,
          upon request to the Controller:
        </p>
        <ul>
          <li>Confirmation of the existence of processing;</li>
          <li>Access to the data;</li>
          <li>Correction of incomplete, inaccurate, or outdated data;</li>
          <li>
            Anonymization, blocking, or deletion of unnecessary, excessive, or
            data processed in violation of current legislation;
          </li>
          <li>Data portability to another service provider or product;</li>
          <li>
            Deletion of personal data processed with the consent of the data
            subject, except in the cases provided in Art. 16 of Law No.
            13,709/2018;
          </li>
          <li>
            Information about public and private entities with which the
            Controller has shared data;
          </li>
          <li>
            Information about the possibility of not providing consent and the
            consequences of refusal;
          </li>
          <li>
            Revocation of consent, as provided in §5 of Art. 8 of Law No.
            13,709/2018.
          </li>
        </ul>
        <p>
          The exercise of the rights described above, complaints, or other
          communications must be made by express request from the data subject
          or their legally constituted representative and sent to STARTA
          through the contact channels provided at the end of this Policy.
        </p>
      </section>
      <section>
        <h4>10. RESPONSIBILITIES</h4>
        <p>
          STARTA will not be held responsible for damages resulting from
          the improper treatment of data made public by the data subject
          themselves, nor in other circumstances such as:
        </p>
        <ul>
          <li>
            If the damage results from the exclusive fault of the data subject
            or third parties; or
          </li>
          <li>
            If the damage results from fortuitous events or force majeure.
          </li>
        </ul>
      </section>
      <section>
        <h4>11. CONFIDENTIALITY OF INFORMATION:</h4>
        <p>
          To ensure User security, all information shared with STARTA is
          kept confidential, as provided by the General Data Protection Law (Law
          13,709/2018).
        </p>
        <p>
          For the development of innovative projects and solutions in the
          Technology field, STARTA ensures confidentiality, the duty of
          confidentiality, and non-disclosure of confidential information.
        </p>
      </section>
      <section>
        <h4>12. SECURITY MEASURES:</h4>
        <p>
          To ensure the confidentiality, integrity, and availability of data and
          information systems used, STARTA adopts technical,
          organizational, and administrative measures to protect personal data
          from unauthorized access and situations of accidental or unlawful
          destruction, loss, alteration, communication, or dissemination, risks,
          and cyber attacks, as per the provisions of the General Data
          Protection Law (Law No. 13,709/18).
        </p>
        <p>
          Thus, STARTA makes best efforts to preserve user data privacy
          with currently available technology. However, it cannot be guaranteed
          that employed resources are entirely secure, especially those through
          which unauthorized access and violation can occur, using social
          engineering methods and devices developed to obtain information
          improperly.
        </p>
        <p>
          Therefore, STARTA advises its Users to also adopt appropriate
          measures to protect themselves from unauthorized and improper
          violations, such as installing security software, preserving access
          data and passwords.
        </p>
        <p>
          STARTA commits to notifying the National Authority and the Data
          Subject about security incidents that may pose relevant risks or
          damages to data subjects, following legal deadlines and regulations.
        </p>
      </section>
      <section>
        <h4>13. SECURITY INCIDENT:</h4>
        <p>
          In the event of a cybersecurity risk or security incident resulting in
          possible damage or harm to the processing and storage of data and
          information of Employees, Customers, Business Partners, and Service
          Providers, STARTA will notify the National Data Protection
          Authority within 2 (two) business days from the date of knowledge of
          the fact, providing information about:
        </p>
        <ul>
          <li>The nature of the affected personal data;</li>
          <li>Information about the data subjects involved;</li>
          <li>
            Technical and security measures to be used for data protection;
          </li>
          <li>Risk profile related to the incident;</li>
          <li>
            Measures to be taken to reverse or mitigate the effects of the
            security incident.
          </li>
          <li>
            The User will also be notified, in the form of an incident alert,
            about the events that occurred.
          </li>
        </ul>
      </section>
      <section>
        <h4>14. HOW WE USE COOKIES:</h4>
        <p>
          Cookies are small data sent by a web server to your browser and stored
          on your computer or personal device. To offer a better experience in
          using our web services and applications, we may use session cookies
          (which expire when you close the browser) and persistent cookies
          (which remain on your computer until you delete them). This type of
          information is collected to make the service more useful and provide a
          more convenient and valuable experience for you.
        </p>
        <p>
          We use two categories of cookies: Cookies served directly by us to
          your computer or device so that we can recognize you when you return
          to our site, and third-party cookies used for access statistics,
          security, and site availability. You can manage cookies directly in
          your browser.
        </p>
      </section>
      <section>
        <h4>15. APPLICABLE LEGISLATION</h4>
        This Policy will be governed, interpreted, and enforced in accordance
        with the laws in force in the Federative Republic of Brazil, especially
        the provisions of Law No. 13,709/2018 (LGPD) and GDPR - General Data
        Protection Regulation.
      </section>
      <section>
        <h4>16. ENTITY RESPONSIBLE FOR DATA PROCESSING:</h4>
        <p>
          The entity responsible for data processing is{" "}
          <b> STARTA TECNOLOGIA DA INFORMAÇÃO LTDA. (STARTA)</b>, a
          private legal entity registered with CNPJ under No.
          16,713,376/0001-83.
        </p>
      </section>
      <section>
        <h4>17. DATA PROTECTION OFFICER:</h4>
        <p>Name: Diógenes Dorneles da Rosa</p>
        <p>E-mail: jony@startaideia.com.br</p>
      </section>
      <section>
        <p>Last Update Date: October 2023.</p>
      </section>
      <section>
        <h4>GLOSSARY</h4>
        <p>In this Policy, the terms have the following meanings:</p>
        <p>
          ● Data Protection Laws: all Applicable Laws related to Personal Data
          Processing.
        </p>
        <p>
          ● Personal Data: all information regarding individuals that can be
          directly or indirectly identified, through information such as name,
          CPF, RG, worker identification number, electronic identification
          (email, IP, smartphones), affiliation.
        </p>
        <p>
          ● Data Processing: any operation performed with personal data, such as
          collection, production, reception, classification, use, access,
          reproduction, transmission, distribution, processing, filing, storage,
          elimination, evaluation, or control of information, modification,
          communication, transfer, dissemination, or extraction;
        </p>
        <p>
          ● International Data Transfer: the procedure by which the
          Agent/Intervener in export and import operations of goods processes,
          stores, and transfers personal data and information between different
          countries.
        </p>
        <p>
          ● Data Subject: a natural person to whom personal data refers, which
          is the subject of processing;
        </p>
        <p>
          ● Controller: a natural or legal person, public or private,
          responsible for decisions regarding the processing of personal data;
        </p>
        <p>
          ● Processor: a natural or legal person, public or private, who
          processes personal data on behalf of the controller;
        </p>
        <p>
          ● Data Protection Officer: a person appointed by the controller and
          processor to act as a channel of communication with data subjects and
          the National Data Protection Authority (ANPD);
        </p>
        <p>
          ● Consent: a free, informed, and unequivocal expression by which the
          data subject agrees to the processing of their personal data for a
          specific purpose;
        </p>
        <p>
          ● Deletion: the exclusion of data or a set of data stored in a
          database, regardless of the procedure used;
        </p>
        <p>
          ● GDPR: General Data Protection Regulation, a set of rules and
          regulations for the use of data in the digital environment created by
          the European Parliament and Council of the European Union.
        </p>
        <p>
          ● National Data Protection Authority (ANPD): a public administration
          body responsible for ensuring, implementing, and overseeing compliance
          with the General Data Protection Law.
        </p>
        <p>
          ● Service Providers: individuals or legal entities, without an
          employment relationship, hired by Starta to develop an activity
          or carry out projects, for remuneration.
        </p>
        <p>
          ● Business Partners: all stakeholders who have some type of interest,
          influence, or impact on the activities and results of our
          organization. This includes customers, partners, employees, suppliers,
          or other groups that may affect or be affected by the company's
          operations.
        </p>
      </section>
    </div>
  );
}

export default EN;
