import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Logos from "../../assets/logos";
import routesPaths from "../../routes/routesPaths";
import styles from "./styles.module.scss";

function Footer() {
  return (
    <footer className={styles.background}>
      <div className={styles.container}>
        <div className={styles.column}>
          <Logos.StartaideiaWhite className={styles.logo} />
          <p>
            <a href="tel:+5551993872616" className={styles.link}>
              +55 (51) 99387-2616
            </a>
            {' '}/{' '}
            <a href="mailto:contato@startaideia.com.br" className={styles.link}>
              contato@startaideia.com.br
            </a>
          </p>
          <p>
            <span className={styles.bold}>
              Hub de Inovação Feevale Techpark
            </span>
            - R. Cândido Silveira, 198 - Auxiliadora, Porto Alegre - RS,
            90540-010
          </p>
          <p>
            <span className={styles.bold}>Instituto Caldeira</span> - Tv. São
            José, 455 - Navegantes, Porto Alegre - RS, 90240-200
          </p>
          <div className={styles.socialMedia}>
            <Link
              to="https://www.instagram.com/starta__/"
              className={styles.link}
              aria-label="Instagram"
            >
              <Logos.InstagramWhite />
            </Link>
            <Link
              to="https://www.facebook.com/startaideia"
              className={styles.link}
              aria-label="Facebook"
            >
              <Logos.FacebookWhite />
            </Link>
            {/* <Link
              to="https://www.twitter.com/startaideia"
              className={styles.link}
              aria-label="Twitter"
            >
              <Logos.TwitterWhite />
            </Link> */}
            <Link
              to="https://www.linkedin.com/company/startaideia"
              className={styles.link}
              aria-label="LinkedIn"
            >
              <Logos.LinkedinWhite />
            </Link>
            <Link
              to="https://www.youtube.com/@startaideia1615"
              className={styles.link}
              aria-label="YouTube"
            >
              <Logos.YoutubeWhite />
            </Link>
          </div>
        </div>
        <div className={styles.column}>
          <Link className={styles.link} to={routesPaths.home}>
            <FormattedMessage id="footer_home" defaultMessage="Home" />
          </Link>
          <Link className={styles.link} to={routesPaths.about}>
            <FormattedMessage id="footer_about" defaultMessage="Sobre" />
          </Link>
          <Link className={styles.link} to={routesPaths.products}>
            <FormattedMessage id="footer_products" defaultMessage="Produtos" />
          </Link>
          <Link className={styles.link} to={routesPaths.cases}>
            <FormattedMessage id="footer_cases" defaultMessage="Cases" />
          </Link>
        </div>
        <div className={styles.column}>
          <Link className={styles.link} to={routesPaths.politics}>
            <FormattedMessage id="footer_dataprotection" />
          </Link>
          <Link className={styles.link} to={routesPaths.security}>
            <FormattedMessage id="footer_incidentresponse" />
          </Link>
          <Link className={styles.link} to={routesPaths.support}>
            <FormattedMessage id="footer_clientportal" />
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
