import { LOCALES } from "./locales";

// How it works
/*
  home_banner_title

  home_ - refers to page
  _banner_ - refers to section on page
  _title - refers to item on section

*/

export const messages = {
  [LOCALES.SPANISH]: {
    /* GLOBAL COMPONENTS */

    // Navbar Component
    navbar_home: "Inicio",
    navbar_about: "Acerca de",
    navbar_products: "Soluciones",
    navbar_cases: "Casos",
    navbar_blog: "Blog",
    navbar_careers: "Carreras",
    navbar_startaProducts: "Productos Starta",
    navbar_softwareFactory: "Fábrica de Software",
    navbar_english: "Inglés",
    navbar_spanish: "Español",
    navbar_portuguese: "Portugués",

    // Footer Component
    footer_home: "Inicio",
    footer_about: "Acerca de",
    footer_products: "Soluciones",
    footer_cases: "Casos",
    footer_blog: "Blog",
    footer_newsletter_title:
      "Actualizaciones diarias para el éxito de su negocio",
    footer_newsletter_input: "Coloque su dirección de correo electrónico",
    footer_dataprotection: "Política de privacidad",
    footer_incidentresponse: "Política de respuesta a incidentes",
    footer_clientportal: "Portal del cliente",

    whatsapp_message: "Hable con nosotros",

    /* HOME PAGE */

    // Banner Section
    home_banner_title:
      "Uniendo creatividad y tecnología para crear soluciones digitales de alto impacto",
    home_banner_description:
      "Estamos comprometidos a hacer del mundo digital un lugar mejor, una realidad donde las empresas puedan lograr sus objetivos y las personas puedan vivir su vida de manera más fácil y conveniente.",
    home_banner_button: "Productos Starta",

    // Stats Section
    home_stats_data_1: "clientes satisfechos",
    home_stats_data_2: "Usuarios en plataformas",
    home_stats_data_3: "Starters activos",

    // Soluctions Section
    home_soluctions_title: "Nuestras Soluciones",
    home_soluctions_develpment_title: "Desarrollo de aplicaciones móviles",
    home_soluctions_develpment_text:
      "Creamos aplicaciones personalizadas para dispositivos iOS y Android para ayudar a nuestros clientes a conectarse con sus usuarios de manera efectiva.",
    home_soluctions_web_title: "Desarrollo de sistemas web",
    home_soluctions_web_text:
      "Ofrecemos desarrollo de sistemas web personalizados, incluido el desarrollo de sitios web, sistemas de gestión de contenido y aplicaciones web.",
    home_soluctions_conception_title: "Diseño",
    home_soluctions_conception_text:
      "Diseñamos soluciones personalizadas con nuestro enfoque innovador para el diseño de software.",
    home_soluctions_outsourcing_title: "Outsourcing",
    home_soluctions_outsourcing_text:
      "Potenciamos tu negocio con nuestros servicios tecnológicos externalizados",
    home_soluctions_scan_title: "Digitalización para empresas",
    home_soluctions_scan_text:
      "Transforme su empresa con nuestro innovador método de digitalización y descubra nuevos horizontes de crecimiento y éxito.",

    // Clients Section
    home_clients_title: "Clientes",

    //Testimonials Section
    home_testimonials_title: "Testimonios de nuestros clientes",

    //Contact Section
    home_bannerContact_title: "Acelere el éxito de su negocio con Starta",
    home_bannerContact_button: "Contáctenos",

    // Blog Section
    home_blog_button: "Ver Todo",

    /* ABOUT PAGE */

    // Banner Section
    about_banner_title:
      "Transformamos el mundo a través de tecnología y soluciones inteligentes",
    about_banner_description:
      "Somos una empresa comprometida con la transformación a través de la tecnología y el trabajo colaborativo. Lo hacemos a través de soluciones creativas e inteligentes que ayudan a los emprendedores a materializar y evolucionar sus proyectos",

    // Culture Section
    about_culture_title: "Cultura de Starta",
    about_culture_description:
      "Creemos que crear una empresa donde a la gente le guste trabajar se basa en las relaciones, el crecimiento y el impacto. Actuamos en el mercado tecnológico hace más de 10 años, creamos proyectos que generen valor para las empresas y la sociedad a través del trabajo en equipo.",
    about_culture_mission_title: "Misión",
    about_culture_mission_text:
      "Facilitar la transformación digital de las empresas, fusionando diseño e innovación para entregar soluciones útiles y de alta calidad.",
    about_culture_vision_title: "Visión",
    about_culture_vision_text:
      "Ser referente nacional en el desarrollo de soluciones tecnológicas en el mercado asegurador.",
    about_culture_values_title: "Valores",
    about_culture_values_text:
      "Responsabilidad, Compromiso, Agilidad, Ética, Colaboración, Incentivo y Valoración.",

    // Hub Section
    about_hub_title: "Dónde estamos",

    // Testimonials Section
    about_testimonials_title: "Lo que dicen nuestros Startas sobre nosotros",

    /* OUTSOURCING PAGE */

    // Banner Section
    outsourcing_banner_title: "Outsourcing",
    outsourcing_banner_description:
      "Encontramos profesionales altamente calificados para desarrollar su negocio.",
    outsourcing_banner_button: "¡Quiero contratar!",

    // Outsourcing Solutions Section
    outsourcing_solutions_title: "Soluciones de Outsourcing de Starta",
    outsourcing_solutions_description:
      "Enfóquese en las actividades principales de su negocio y permítanos ocuparnos del resto. \n Vea cómo Starta puede ayudarlo:",
    outsourcing_solutions_outsourcing_title: "Outsourcing",
    outsourcing_solutions_outsourcing_description:
      "¡Queremos ayudarte a crecer aún más! Encontramos profesionales cualificados que forman e impulsan tu negocio y los gestionamos en común, manteniendo a estos profesionales satisfechos y comprometidos con tu negocio.",
    outsourcing_solutions_agilesquads_title: "Squads ágiles",
    outsourcing_solutions_agilesquads_description:
      "Contrate squads ágiles, equipos autogestionados y multidisciplinarios que utilicen metodología ágil para entregar resultados precisos y rápidos. Asignamos un equipo dedicado listo para trabajar en sus proyectos estratégicos con consistencia, gestión y visibilidad.",

    // Secondary Banner Section
    outsourcing_secondary_banner_title:
      "Garantizamos un proceso ágil y eficiente desde la selección hasta el seguimiento de los profesionales. Aceleramos tu transformación digital.",

    // About Solutions Section
    outsourcing_aboutsolutions_title: "Acerca de las soluciones",
    outsourcing_aboutsolutions_outsourcing_button: "Outsourcing",
    outsourcing_aboutsolutions_outsourcing_title:
      "Nuestros talentos están listo para sumar",
    outsourcing_aboutsolutions_outsourcing_description:
      "Para negocios que buscan la transformación digital de forma ágil y con calidad. A través de nuestra metodología y experiencia en el área de la tecnología, encontramos el talento y lo destinamos a tu negocio, buscando la combinación de técnica y cultura. Nos dedicamos a brindar la mejor experiencia al profesional y a nuestro cliente.",
    outsourcing_aboutsolutions_agilesquads_button: "Squads Ágiles",
    outsourcing_aboutsolutions_agilesquads_title:
      "Asignación de Escuadrones a pedido",
    outsourcing_aboutsolutions_agilesquads_description:
      "Para negocios que necesitan agilidad, escalabilidad y que no disponen de tiempo, tenemos la solución ideal. Le entregamos toda la solución de outsourcing, pero a través de equipos completos, cohesionados y experimentados.",

    // How We Execute Section
    outsourcing_howweexecute_title: "Cómo ejecutamos",
    outsourcing_howweexecute_talentattraction_title: "Atracción de Talento",
    outsourcing_howweexecute_talentattraction_description1:
      "Busque el perfil técnico y conductual ideal para su negocio a través de Recursos Humanos especializado;",
    outsourcing_howweexecute_talentattraction_description2:
      "Psicólogos organizacionales con experiencia en el área tecnológica;",
    outsourcing_howweexecute_talentattraction_description3:
      "Además de habilidades blandas y duras - Buscamos el fit cultural entre Cliente y Profesional.",
    outsourcing_howweexecute_retaining_talent_title:
      "Reclutamiento de talentos",
    outsourcing_howweexecute_retaining_talent_description1:
      "Seguimiento mensual para alineamiento de expectativas y apoyo a los profesionales;",
    outsourcing_howweexecute_retaining_talent_description2:
      "Evaluación y retroalimentación de mitad de año;",
    outsourcing_howweexecute_retaining_talent_description3:
      "Bonificación por Desempeño;",
    outsourcing_howweexecute_retaining_talent_description4:
      "Incentivos que apoyen la Salud Física y Mental;",
    outsourcing_howweexecute_retaining_talent_description5:
      "Incentivos a la Educación y Superación Profesional.",
    outsourcing_howweexecute_optimized_management_title: "Gestión Optimizada",
    outsourcing_howweexecute_optimized_management_description:
      "Realizamos la gestión compartida de profesionales. A través de citas quincenales con los clientes, alineamos puntos de mejora y estamos siempre disponibles para la intermediación necesaria.",

    // Our Methodology Section
    outsourcing_ourmethodology_title: "Nuestra Metodología",
    outsourcing_ourmethodology_subtitle:
      "Cultura sólida y gestión centrada en las personas",
    outsourcing_ourmethodology_description:
      "A través de una gestión centrada en las personas y una cultura sólida que guía a nuestro equipo, desarrollamos el talento y creamos un entorno motivador. Somos muy cercanos, creemos que promover un ambiente de trabajo saludable, incentivar y empoderar al equipo es entregar lo mejor a nuestros clientes.",
    outsourcing_ourmethodology_culture_description:
      "Cultura, Colaboración y Pertenencia",
    outsourcing_ourmethodology_followup_description:
      "Seguimiento y Desarrollo del Talento",
    outsourcing_ourmethodology_management_description:
      "Gestión Humanizada: ¡Somos un equipo!",

    // Benefits for your business Section
    outsourcing_businessbenefits_title: "Beneficios para tu negocio",
    outsourcing_businessbenefits_attraction_description:
      "Atracción y Reclutamiento Eficiente",
    outsourcing_businessbenefits_management_description:
      "Gestión de Personas Optimizada - Gestión Compartida",
    outsourcing_businessbenefits_reduction_description:
      "Reducción de Turnover, Políticas de Refuerzo y Reconocimiento de los Profesionales",
    outsourcing_businessbenefits_professionals_description:
      "Profesionales Satisfechos, Motivados y Comprometidos con los objetivos del Cliente",

    // Testimonials from our customers Section
    outsourcing_testimonialscustomers_title: "Testimonios de nuestros clientes",
    outsourcing_testimonialscustomers_firsttestimonial_description:
      "“Es muy bueno poder contar con el equipo, porque son totalmente disponibles y flexibles, no tuvimos ningún problema.”",
    outsourcing_testimonialscustomers_secondtestimonial_description:
      "“Están preocupados por entender nuestro negocio, no veo diferencia entre el equipo interno y el equipo de Starta, el interés y compromiso es realmente bueno, llevan la camiseta.”",
    outsourcing_testimonialscustomers_thirdtestimonial_description:
      "“Esta alianza que tenemos con Starta es muy estratégica para nosotros, ya que son los profesionales más importantes del equipo en este momento.”",
    outsourcing_testimonialscustomers_fourthtestimonial_description:
      "“Nunca tuve ningún tipo de problema con los profesionales de Starta, entraron y encajaron perfectamente aquí, fue súper fácil.”",

    // Contact Us Section
    outsourcing_contactus_form_title: "¿Quiere saber más? ¡Entre en contacto!",
    outsourcing_contactus_faq_title: "Preguntas más frecuentes",
    outsourcing_contactus_faq_questionone:
      "¿Cuánto tiempo puede permanecer un profesional asignado a través de Outsourcing?",
    outsourcing_contactus_faq_answerone:
      "No hay límite de tiempo para la asignación de profesionales, el contrato de asignación puede ser de seis meses a un año y puede ser prorrogado indefinidamente.",
    outsourcing_contactus_faq_questiontwo:
      "¿Cuántos profesionales pueden componer un squad ágil?",
    outsourcing_contactus_faq_answertwo:
      "El número de profesionales y sus especialidades se definirá en función de la demanda de tu reto, y de acuerdo a tus necesidades. No hay una cantidad fija, pero siempre se tiene cuidado de formar un equipo completo y eficiente para que podamos entregar rápidamente.",
    outsourcing_contactus_faq_questionthree:
      "¿Cuáles son las especialidades profesionales que encuentro a través de las Soluciones de Outsourcing y Agile Squads de Starta?",
    outsourcing_contactus_faq_answerthree:
      "Nuestro equipo cuenta con profesionales altamente calificados de diferentes áreas de desarrollo de software, análisis de calidad, diseño, UX/UI, gestión de proyectos, agilidad, marketing y otra.",
    outsourcing_contactus_faq_button: "Mandar",

    /* JOBS PAGE */

    // Banner Section
    jobs_banner_title:
      "Está listo para abordar en el camino de la transformación digital?",
    jobs_banner_description:
      "Si te apasiona la tecnología y quieres trabajar en proyectos innovadores, únete a un equipo que cree que es posible construir un mundo mejor a través de la tecnología.",
    jobs_banner_hashtag: "#VemSerStarta",
    jobs_banner_button: "Nuestras vacantes",

    // Team work Section
    jobs_teamwork_title: "¡Aquí, el trabajo en equipo se toma en serio!",
    jobs_teamwork_description:
      "Nuestro equipo está formado por personas apasionadas por la innovación y que trabajan juntas por un gran objetivo: ¡facilitar la aplicación de ideas en el mundo! Sabemos cuánto la fuerza del poder colaborativo y una visión optimista son capaces de transformar realidades. Por ello, ofrecemos un entorno dinámico y flexible, confiando siempre en la confianza de cada profesional.",

    // The Starta culture Section
    jobs_startaculture_title: "La Cultura Starta",
    jobs_startaculture_description:
      "Nosotros trabajamos, valoramos y reconocemos Starters:",
    jobs_startaculture_followup_title:
      "Seguimientos y Feedback’s mensual con los líderes",
    jobs_startaculture_followup_description1: "- Escucha activa",
    jobs_startaculture_followup_description2: "- Alineación de Experiencia",
    jobs_startaculture_followup_description3: "- Desarrollo de carrera",
    jobs_startaculture_evaluation_title: "Evaluación Semestral",
    jobs_startaculture_evaluation_description1:
      "- Evaluación Técnica y Conductual",
    jobs_startaculture_evaluation_description2:
      "- Bonificación por rendimiento",
    jobs_startaculture_talk_title: "Talk Starta",
    jobs_startaculture_talk_description:
      "Charla semanal con todo el equipo de Starta, donde compartimos conocimientos y nos mantenemos al tanto de las novedades. ¡Porque aquí caminamos juntos!",
    jobs_startaculture_coffee_title: "Café con los Entrantes",
    jobs_startaculture_coffee_description:
      "Tiempo para conectar más, intercambiar ideas sobre el trabajo, la rutina y el mundo.",
    jobs_startaculture_flexibility_title: "Flexibilidad",
    jobs_startaculture_flexibility_description1: "- Trabajo Remoto o Híbrido",
    jobs_startaculture_flexibility_description2: "- Horario flexible",
    jobs_startaculture_flexibility_description3: "- Vacaciones pagadas",
    jobs_startaculture_incentives_title: "Incentivos",
    jobs_startaculture_incentives_description1:
      "- Incentivo a la Salud y la Educación",
    jobs_startaculture_incentives_description2:
      "- Telemedicina y Seguros de Vida",
    jobs_startaculture_incentives_description3:
      "- Programa de indicación de éxito",
    jobs_startaculture_incentives_description4: "- Regalo por cumpleaños",

    // Our Mantras Section
    jobs_ourmantras_title: "Nuestras Mantras",
    jobs_ourmantras_feedback_description:
      "Los comentarios son siempre bienvenidos.",
    jobs_ourmantras_learn_description:
      "Aprender a enseñar, enseñar a evolucionar",
    jobs_ourmantras_health_description: "Tu salud importa",
    jobs_ourmantras_afraid_description: "No tengas miedo de cometer errores",
    jobs_ourmantras_comunication_description:
      "Comunicar y estar abierto a escuchar",
    jobs_ourmantras_exercise_description: "Ejercita tu creatividad",
    jobs_ourmantras_freedom_description: "Libertad con responsabilidad",
    jobs_ourmantras_count_description: "Contar con el equipo",

    // What our Starters say about us Section
    jobs_aboutus_title: "Lo que nuestros Starters dicen de nosotros",
    jobs_aboutus_description:
      "Starta me permite involucrarme en proyectos desafiantes con soluciones innovadoras que revolucionan el día a día de diferentes empresas y personas. Es extremadamente gratificante poder ver el impacto positivo en las empresas y en las personas que utilizan las plataformas desarrolladas por nosotros.",

    // Linkedin Banner Section
    jobs_linkedinbanner_title:
      "Síguenos en Linkedin y acompáñanos con  las noticias y oportunidades",
    jobs_linkedinbanner_button: "Ir a LinkedIn",

    // Available opportunities Section
    jobs_opportunities_title: "Oportunidades disponibles",
    jobs_opportunities_empty:
      "Actualmente no tenemos ninguna vacante abierta. ¡Pero mantente atento a nuestras redes para estar al tanto de las novedades y no perder ninguna oportunidad!",

    // Contact us Section
    jobs_contact_title:
      "Únete a nosotros y revolucionemos el mercado de tecnología",
    jobs_contact_description:
      "Estamos emocionados de tenerte como parte de nuestro equipo. Completar la información al costado es el primer paso hacia un viaje exitoso con nosotros. Su contribución será fundamental para nuestro crecimiento y el logro de metas ambiciosas.",

    /* CASES PAGE */

    // Banner Section
    cases_banner_title:
      "Vea nuestros casos de éxito en soluciones de software para empresas",
    cases_banner_description:
      "Ofrecemos soluciones completas para empresas que quieren transformar el mundo a través de la tecnología. Nuestro portafolio incluye casos exitosos con gran impacto tecnológico, demostrando nuestra experiencia y competencia para ayudar a las empresas a alcanzar sus objetivos.",

    cases_hygia_description:
      "Transforme su salud a un enfoque preventivo, reactivo y predictivo con una app innovadora. Descubre una forma revolucionaria de cuidar tu salud, con un sistema integrado que te permite monitorear y mejorar tu salud de manera más eficiente y efectiva. Con esta solución de salud, tendrá todas las herramientas que necesita para mantenerse saludable y bien cuidado en cualquier momento y en cualquier lugar.",
    cases_tokyo_description:
      "Plataformas digitales que simplifican los servicios de corredores y consultores de negocios, brindando un mayor control y transparencia sobre sus ventas y comisiones,  permitiéndoles evolucionar constantemente. Con una experiencia de usuario optimizada, nuestras soluciones digitales ayudan a los profesionales a administrar su negocio de manera eficiente, lo que les permite concentrarse en brindar un servicio al cliente excepcional",
    cases_sicredi_description:
      "Desarrollamos Seguros Sicredi para paneles solares fotovoltaicos, con el objetivo de proteger a los inversionistas contra imprevistos causados ​​por daños inesperados y accidentes. Con un enfoque centrado en el usuario, nuestra solución ofrece una experiencia de usuario optimizada y fácil de usar, lo que le permite proteger su inversión con confianza y tranquilidad",
    cases_ccg_description:
      "Desarrollamos un proceso simple y ágil para la venta de planes de salud CCG, con la atención y el cuidado que tus clientes merecen. Con nuestro enfoque centrado en el usuario, el proceso de ventas se vuelve intuitivo y sin complicaciones, lo que le permite concentrarse en brindar un servicio personalizado y de calidad. Con nuestra ayuda, puede asegurarse de que sus clientes reciban la mejor solución de plan de salud para sus necesidades, sin tener que pasar por un proceso de venta estresante o lento.",
    cases_anjo_description:
      "Desarrollado en sociedad con el grupo Caburé, revolucionará sus ventas de seguros de vida y servicios de salud. Con esta innovadora app podrá gestionar sus ventas de forma ágil, segura y eficiente, garantizando más éxito y satisfacción a las necesidades de su negocio. Nuestra solución se creó con un enfoque centrado en el usuario para brindar una experiencia de usuario fluida y fácil de usar, lo que le permite concentrarse en brindar un servicio al cliente excepcional.",
    cases_tecredi_description:
      "Con la plataforma de financiación de vehículos 100% digital, ahora es posible comprar y vender coches de forma segura y sin burocracia. Nuestra aplicación simplifica todo el proceso, haciendo que comprar o vender un vehículo sea una experiencia fácil y fluida. Con un enfoque centrado en el usuario, nuestra app es intuitiva y fácil de usar, y brinda una experiencia de usuario impecable desde la búsqueda de automóviles hasta la finalización de la financiación. Con nuestra ayuda, puede comprar o vender su automóvil con confianza, sabiendo que está obteniendo un trato seguro y sin estrés.",

    /* PRODUCTS PAGE */

    // Banner Section
    products_banner_title:
      "Vea algunos de los productos desarrollados por Starta",
    products_banner_description:
      "Descubra nuestras soluciones inteligentes diseñadas para ser altamente personalizables. Potencializa la oferta de servicios y productos a través de nuestras plataformas digitales y garantiza la credibilidad que tu negocio merece.",

    // Products Section
    products_link_button_text: "Sepa más",
    products_health_title: "Portal de Salud",
    products_health_description:
      "El Portal de Salud es una plataforma que ofrece una gama de soluciones relacionadas con la venta de productos y servicios de salud. Gestiona y monitorea todo el proceso de venta, desde la simulación, contratación, registro, envío de documentos y pago. Personaliza la plataforma con la identidad de tu empresa y ofrece a tus clientes un servicio completamente digital.",
    products_open_description:
      "Open es una plataforma que promueve los retos de innovación empresarial. El objetivo es conectar startups a grandes empresas en la búsqueda de soluciones innovadoras a retos empresariales o el desarrollo de productos y servicios innovadores para el mercado nacional.",
    products_insuron_description:
      "InsurON es la plataforma de venta de seguros de Starta Ideia. Brindamos un viaje digital, con entrega rápida y baja inversión, aprovechando y mejorando los productos de seguros en los canales digitales. Solución recomendada para corredores con múltiples productos y aseguradoras asociadas.",
    products_exohub_description:
      "EXOHUB es el resultado de la colaboración entre Starta y los principales Hubs, Coworkings y Laboratorios de Innovación del sur de Brasil. Creamos un ecosistema único para el intercambio de conocimientos y experiencias entre empresas, emprendedores, educación, networking y creación de nuevos negocios. Benefíciese de espacios físicos y virtuales diseñados para impulsar proyectos innovadores y creativos en una comunidad en constante crecimiento y evolución.",

    /* HEALTH PAGE */

    //Banner Section
    healthProject_banner_title:
      "Primera plataforma de Health Commerce de Brasil",
    healthProject_banner_subtitle:
      "Revolucionando la distribución de servicios y planes de salud a través de la tecnología",
    healthProject_banner_button: "¡Agende una demostración!",

    //About Section
    healthProject_about_title: "Portal Saúde, plataforma adaptada a su negocio",
    healthProject_about_description:
      "El Portal Saúde es una plataforma White Label que ofrece una gama de soluciones relacionadas con la distribución de servicios de salud. Gestione y acompañe todo el proceso de venta, desde la simulación, contratación, registro, envío de documentos y pago. Personaliza la plataforma con la identidad de tu empresa y ofrece a tus clientes una experiencia completamente digital.",

    //Solutions Section
    healthProject_solutions_title: "Nuestras soluciones",
    healthProject_solutions_healthPlan_title: "Plan de salud y odontología",
    healthProject_solutions_healthPlan_description:
      "Plataforma Whitelabel para simulación y contratación online de Planes de Salud, Dentales y de Salud Ocupacional. Una plataforma con procesos completamente digitales.",
    healthProject_solutions_plans_title: "Paquete de servicios de salud",
    healthProject_solutions_plans_description:
      "Los exámenes y chequeos desempeñan un papel fundamental en la promoción de la salud, la prevención de enfermedades y el manejo eficaz de las condiciones médicas, contribuyendo a una vida más sana y plena. Con nuestra plataforma acercamos a las organizaciones a este propósito a través de nuestra tecnología.",
    healthProject_solutions_simulator_title: "Simulador de planes de salud",
    healthProject_solutions_simulator_description:
      "El Simulador de Planes de Salud permite a los consultores crear y remitir la propuesta comercial a sus clientes directamente a través de la plataforma. Permita que sus clientes simulen una propuesta mediante el autoservicio.",
    healthProject_solutions_dps_title: "Declaración de salud personal",
    healthProject_solutions_dps_description:
      "Su declaración de salud totalmente personalizada y digital, ya integrada con un innovador proceso CTP (cobertura temporal parcial), combina tecnología de punta y procesos eficientes para agilizar la aceptación de los beneficiarios en los planes de salud y reducir los riesgos de aceptación.",

    //Demonstration Section
    healthProject_demonstration_title: "Navegue por la plataforma",
    healthProject_demonstration_button: "¡Agende una demostración!",

    //Services Section
    healthProject_services_title:
      "Una plataforma completa para servicios de salud.",
    healthProject_services_description:
      "Soluciones altamente personalizables para su negocio con alta tecnología y entrega rápida.",
    healthProject_services_text: "Indicado para:",
    healthProject_services_hospitals: "Hospitales",
    healthProject_services_clinics: "Clínicas",
    healthProject_services_healthOperators: "Operadoras de Salud",
    healthProject_services_creditCooperatives: "Cooperativas de Crédito",
    healthProject_services_financialInstitutions: "Instituciones Financieras",
    healthProject_services_brokerages: "Corredoras",

    //For your business Section
    healthProject_business_title: "Soluciones para tu negocio",
    healthProject_business_checklist_title:
      "Portal Saúde, una plataforma adaptada a su negocio",
    healthProject_business_checklist_description:
      "Portal Saúde es una plataforma White Label que ofrece una gama de soluciones relacionadas con la venta de servicios de salud. Gestione y acompañe todo el proceso de venta: desde la simulación, contratación, registro, envío de documentos y pago. Personaliza la plataforma con la identidad de tu empresa y ofrece a tus clientes una experiencia completamente digital.",
    healthProject_business_checklist_digital:
      "100% digital, tecnología de punta",
    healthProject_business_checklist_ai:
      "Análisis de documentos AI (Inteligencia Artificial)",
    healthProject_business_checklist_dps: "DPS/CPT digitales",
    healthProject_business_checklist_signature: "Firma de contrato",
    healthProject_business_checklist_payment:
      "Métodos de pago (PIX, comprobante bancario y tarjeta)",
    healthProject_business_checklist_broker: "Área de corredores",
    healthProject_business_checklist_lgpd: "Seguridad de datos y LGPD",
    healthProject_business_checklist_tiss: "Estándar TISS",
    healthProject_business_products_title:
      "Impulsa la transformación digital de tus servicios con el Portal de Salud:",
    healthProject_business_products_channel:
      "Canal totalmente digital para la distribución de servicios sanitarios",
    healthProject_business_products_platform: "Plataforma personalizable",
    healthProject_business_products_clients:
      "Simplificación del proceso de captación de clientes",
    healthProject_business_products_efficiency:
      "Mayor eficiencia operativa y gestión centralizada",
    healthProject_business_products_conversion: "Alta conversión de ventas",
    healthProject_business_products_competitive: "Alta competitividad",
    healthProject_business_products_communication:
      "Comunicación eficiente en todas las etapas",

    //Contact Us Section
    healthProject_contact_title:
      "¿Estás interesado en saber más sobre el Portal de Salud?",
    healthProject_contact_description:
      "Nuestro equipo especializado está listo para responder a sus preguntas y ofrecerle más información. Complete el siguiente formulario y nos comunicaremos con usted a la brevedad.",
    healthProject_contact_form_title: "Envíe su mensaje",
    healthProject_contact_form_name: "Nombre",
    healthProject_contact_form_email: "Email",
    healthProject_contact_form_phone: "Teléfono",
    healthProject_contact_form_message: "Mensaje",
    healthProject_contact_form_popup: "!Agenta una demostración!",

    //CAROUSEL
    carousel_title: "Historias de éxito",
    carousel_button: "Ver más",
    carousel_hygia_title: "Hygia Health",
    carousel_hygia_text:
      "Una forma revolucionaria de cuidar tu salud, con un sistema integrado que te permite monitorear y mejorar tu salud de una manera más eficiente y efectiva.",
    carousel_ccg_title: "CCG Saúde",
    carousel_ccg_text:
      "Hemos desarrollado un proceso de venta de planes de salud CCG sencillo y ágil, con la atención y cuidado que tus clientes merecen.",
    carousel_anjo_title: "Anjo",
    carousel_anjo_text:
      "Desarrollado en colaboración con el grupo Caburé, Anjo es una revolución en la venta de seguros de vida y servicios de salud.",

    // Software Factory Page

    //Banner Section
    softwarefactory_banner_title: "Fábrica de software",
    softwarefactory_banner_subtitle:
      "La innovación es nuestro lenguaje nativo. Desarrollamos softwares de alto impacto.",
    softwarefactory_banner_button: "¡Quiero contratar!",

    // About Section
    softwarefactory_about_title:
      "Acelera tu transformación digital con nuestra fábrica de software",
    softwarefactory_about_description:
      "Llevamos más de 7 años desarrollando software personalizado y creando soluciones digitales únicas para nuestros clientes. Trabajamos desde entender el desafío y diseñar la solución, hasta la implementación y lanzamiento al mercado. Somos expertos en desarrollar soluciones para las áreas de seguros, salud y finanzas con innovación, eficiencia y agilidad.",

    // Our Services Section
    softwarefactory_ourservices_title: "¿Como podemos ayudarte?",
    softwarefactory_ourservices_description:
      "Desarrollamos software personalizado para satisfacer las necesidades de su empresa, creamos aplicaciones y sistemas adaptados a sus objetivos.",

    softwarefactory_ourservices_card_migrations_title:
      "Migración del sistema heredado",
    softwarefactory_ourservices_card_migrations_description:
      "Transferimos procesos existentes en sistemas antiguos y obsoletos a plataformas nuevas, modernas y eficientes. Analizamos las limitaciones del sistema y los puntos de mejora y desarrollamos nuevo software con arquitectura y tecnología adecuada a las necesidades del negocio.",

    softwarefactory_ourservices_card_software_title:
      "Desarrollo de sistemas y plataformas web.",
    softwarefactory_ourservices_card_software_description:
      "¿Quieres una experiencia digital única y exclusiva para tu empresa? Ofrecemos servicios de desarrollo personalizados para sistemas web, sitios web y plataformas que entregan valor real al usuario.",

    softwarefactory_ourservices_card_mobile_title:
      "Desarrollo de aplicaciones móviles",
    softwarefactory_ourservices_card_mobile_description:
      "¡Conéctate con tus usuarios de manera efectiva! Creamos aplicaciones personalizadas para iOS y Android que satisfacen las necesidades únicas de cada cliente.",

    // How we work Section
    softwarefactory_howwework_title: "Cómo trabajamos",

    softwarefactory_howwework_planning: "Planificación",
    softwarefactory_howwework_construction: "Construcción",
    softwarefactory_howwework_sustenance: "Sustentación",

    softwarefactory_howwework_planning_challenge_title: "Comprender el desafío",
    softwarefactory_howwework_planning_challenge_description:
      "Este paso se refiere al proceso de comprender plenamente las necesidades y objetivos del proyecto. Implica un análisis cuidadoso de los requisitos y limitaciones del negocio, así como la identificación de obstáculos técnicos. A través de un enfoque colaborativo, trabajamos estrechamente con el cliente para garantizar que todos los aspectos de los desafíos se comprendan claramente.",
    softwarefactory_howwework_planning_requirements_title:
      "Mapeo de requisitos",
    softwarefactory_howwework_planning_requirements_description:
      "En esta etapa del proceso identificamos, documentamos y entendemos información relevante como requisitos funcionales y no funcionales. Esta información se utiliza para guiar el desarrollo del producto, definiendo los recursos, funcionalidades y características que deben implementarse.",
    softwarefactory_howwework_planning_design_title: "Arquitectura y Diseño",
    softwarefactory_howwework_planning_design_description:
      "Se realiza un análisis exhaustivo teniendo en cuenta factores como el rendimiento, la escalabilidad, la seguridad y la usabilidad. A partir de este análisis se definen lineamientos arquitectónicos, eligiendo las tecnologías y plataformas más adecuadas para la solución. Luego, el equipo de diseño utiliza estas pautas para crear interfaces y experiencias de usuario intuitivas y eficientes.",

    softwarefactory_howwework_construction_development_title: "Desarrollo",
    softwarefactory_howwework_construction_development_description:
      "En esta etapa, los desarrolladores utilizan la información recopilada en los pasos anteriores para crear la estructura y la lógica del software. Se utilizan lenguajes de programación, frameworks y bibliotecas adecuadas al proyecto, teniendo en cuenta la eficiencia, escalabilidad y mantenimiento del código. Seguimos las prácticas de codificación más adecuadas, según el desafío y estándares acordados con el cliente.",
    softwarefactory_howwework_construction_testing_title: "Pruebas",
    softwarefactory_howwework_construction_testing_description:
      "En esta etapa se realizan una serie de pruebas para evaluar la calidad, funcionalidad y desempeño de la solución. El equipo de pruebas utiliza técnicas y herramientas apropiadas para planificar, ejecutar y registrar los resultados de las pruebas, identificando e informando cualquier problema encontrado. Esta fase es fundamental para garantizar la calidad y fiabilidad de la solución.",
    softwarefactory_howwework_construction_homolog_title: "Homologación",
    softwarefactory_howwework_construction_homolog_description:
      "En esta fase, las actividades de validación son realizadas por el equipo del cliente para asegurar que la solución cumple con los requisitos inicialmente establecidos y está lista para su uso. Esto implica una revisión de la documentación técnica, las reglas comerciales y los escenarios prácticos de prueba de uso para verificar que la solución sea completa y precisa.",

    softwarefactory_howwework_sustenance_trainning_title:
      "Entrenamiento y documentación",
    softwarefactory_howwework_sustenance_trainning_description:
      "La fase de capacitación y documentación es esencial para garantizar que los usuarios y el personal de soporte utilicen y mantengan adecuadamente los sistemas y las soluciones. Durante esta fase se desarrollan materiales de capacitación como manuales de usuario, tutoriales y guías de referencia, que brindan instrucciones detalladas sobre el funcionamiento y funcionalidades de los sistemas.",
    softwarefactory_howwework_sustenance_maintenance_title:
      "Mantenimiento y Evolución",
    softwarefactory_howwework_sustenance_maintenance_description:
      "Esta etapa comprende actividades de soporte continuo, corrección de problemas y mejora de los sistemas y soluciones implementadas. El equipo de soporte técnico monitoriza y gestiona los sistemas en producción, respondiendo a incidencias, identificando y resolviendo fallos y asegurando la estabilidad y disponibilidad de los servicios.",

    //Stats Section
    softwarefactory_stats_clients: "Clientes satisfechos",
    softwarefactory_stats_projects: "Transacciones en el plataformas",
    softwarefactory_stats_users:
      "De usuarios impactados por nuestras soluciones",

    //Contact Section
    softwarefactory_contact_accordion1_title:
      "¿Cuánto tiempo lleva desarrollar un nuevo proyecto?",
    softwarefactory_contact_accordion1_content:
      "El tiempo necesario para desarrollar un proyecto de nueva tecnología puede variar considerablemente dependiendo de la complejidad del proyecto y los recursos disponibles. Proyectos que tardan de 1 mes a 6 meses, trabajamos con pequeños entregables.",
    softwarefactory_contact_accordion2_title:
      "¿Cuáles son los beneficios de contratar un fábrica de software?",
    softwarefactory_contact_accordion2_content:
      "- Experiencia Técnica que permita el desarrollo de soluciones de alta calidad con las mejores prácticas. \n- Reducción de costos porque al contratar una fábrica de software evitas costos internos de contratación, apacitación e infraestructura.\n- Aseguramiento de calidad y soporte, esto significa que contarás con soporte y acompañamiento desde la implementación hasta la ejecución del proyecto.",
    softwarefactory_contact_accordion3_title:
      "¿Qué tecnologías se utilizan en los proyectos de desarrollo?",
    softwarefactory_contact_accordion3_content:
      "Con base en la comprensión del desafío del proyecto, seleccionamos las tecnologías a utilizar. Podemos mencionar algunos como Java, React Native, Angular, PHP, Flutter, Python, entre otros.",
    softwarefactory_contact_accordion4_title:
      "¿Cualquier empresa puede contratar?",
    softwarefactory_contact_accordion4_content:
      "Sí, cualquier empresa que quiera innovar y emprender una transformación digital puede contar con Starta para el desarrollo de tecnologías.",

    softwarefactory_contact_form_title: "¡Contáctanos!",
    softwarefactory_contact_form_subtitle:
      "Ponte en contacto y conoce cómo Starta puede ayudarte a potenciar tu proyecto. Nuestro equipo está listo para atenderle, resolver todas sus dudas y comenzar una asociación exitosa.",
    contact_form_name: "Inserta tu nombre",
    contact_form_email: "Tu correo electrónico",
    contact_form_phone: "Número de teléfono",
    contact_form_message: "Deja tu mensaje aquí",
    contact_button_send: "Enviar",
    contact_button_success: "¡Mensaje enviado!",
    contact_error: "¡Error! Por favor, inténtelo de nuevo.",
    contact_fill_fields: "Por favor, rellene todos los campos.",
    contact_title: "¡Ponte en contacto con Starta!",
    contact_description:
      "Ponte en contacto y descubre cómo Starta puede ayudarte a impulsar tu proyecto. Nuestro equipo está preparado para ayudarle, responder todas sus preguntas e iniciar una asociación exitosa.",
  },
  [LOCALES.PORTUGUESE]: {
    /* GLOBAL COMPONENTS */

    // Navbar Component
    navbar_home: "Home",
    navbar_about: "Sobre",
    navbar_products: "Soluções",
    navbar_cases: "Cases",
    navbar_blog: "Blog",
    navbar_careers: "Carreiras",
    navbar_startaProducts: "Produtos Starta",
    navbar_softwareFactory: "Fábrica de Software",
    navbar_english: "Inglês",
    navbar_spanish: "Espanhol",
    navbar_portuguese: "Português",

    // Footer Component
    footer_home: "Início",
    footer_about: "Sobre",
    footer_products: "Produtos",
    footer_cases: "Cases",
    footer_blog: "Blog",
    footer_newsletter_title:
      "Atualizações diárias para o sucesso do seu negócio",
    footer_newsletter_input: "Insira o seu endereço de email",
    footer_dataprotection: "Politica de Privacidade",
    footer_incidentresponse: "Plano de Resposta a Incidentes",
    footer_clientportal: "Portal do Cliente",

    whatsapp_message: "Fale conosco",

    /* HOME PAGE */

    // Banner Section
    home_banner_title:
      "Unindo criatividade e tecnologia para criar soluções digitais de alto impacto",
    home_banner_description:
      "Como fábrica de softwares, criamos soluções digitais personalizadas para ajudar as empresas a alcançarem seus objetivos e as pessoas a viverem de maneira mais fácil e conveniente. Transforme suas ideias em realidade no mundo digital.",
    home_banner_button: "Contato",

    // Stats Section
    home_stats_data_1: "Clientes satisfeitos",
    home_stats_data_2: "Usuários nas plataformas",
    home_stats_data_3: "Starters atuantes",

    // Soluctions Section
    home_soluctions_title: "Soluções Starta para o seu negócio",
    home_soluctions_develpment_title: "Fábrica de Software",
    home_soluctions_develpment_text:
      "Desenvolvemos soluções de software exclusivas e eficientes que impulsionam o sucesso do seu negócio. Potencialize seus resultados com a tecnologia.",
    home_soluctions_web_title: "Outsourcing",
    home_soluctions_web_text:
      "Contrate profissionais especializados em tecnologia. Conte com a nossa expertise em recrutamento e seleção e gerenciamento destes profissionais.",
    home_soluctions_conception_title: "Produtos Starta",
    home_soluctions_conception_text:
      "Saiba como nossos produtos podem ser a chave para levar a sua empresa a novos patamares de sucesso. Soluções personalizáveis que já contam com milhares de clientes.",

    // Clients Section
    home_clients_title: "Clientes",

    //Testimonials Section
    home_testimonials_title: "Depoimento de nossos clientes",

    //Contact Section
    home_bannerContact_title: "Acelere o sucesso do seu negócio com a Starta",
    home_bannerContact_button: "Contato",

    // Blog Section
    home_blog_button: "Visualizar Todos",

    /* ABOUT PAGE */

    // Banner Section
    about_banner_title:
      "Transformamos o mundo através da tecnologia e soluções inteligentes",
    about_banner_description:
      "Somos uma empresa comprometida com a transformação através da tecnologia e do trabalho colaborativo. Fazemos isso através de soluções criativas e inteligentes que auxiliam empreendedores a materializar e evoluir seus projetos.",

    // Culture Section
    about_culture_title: "A cultura Starta",
    about_culture_description:
      "Acreditamos que criar uma empresa onde as pessoas gostem de trabalhar se baseia em relacionamentos, crescimento e impacto. Estamos a mais de 10 anos atuando no mercado de tecnologia, buscando através do trabalho em equipe criar projetos que gerem valor para empresas e para a sociedade. ",
    about_culture_mission_title: "Missão",
    about_culture_mission_text:
      "Facilitar a transformação digital de empresas, combinando design e inovação para entregar soluções úteis e de extrema qualidade",
    about_culture_vision_title: "Visão",
    about_culture_vision_text:
      "Ser referência nacional no desenvolvimento de soluções em tecnologia, no mercado de seguros.",
    about_culture_values_title: "Valores",
    about_culture_values_text:
      "Responsabilidade, Comprometimento, Agilidade, Ética, Colaboração, Incentivo e Valorização.",

    // Hub Section
    about_hub_title: "Onde estamos",

    // Testimonials Section
    about_testimonials_title: "O que nossos Starters dizem sobre nós",

    /* OUTSOURCING PAGE */

    // Banner Section
    outsourcing_banner_title: "Outsourcing",
    outsourcing_banner_description:
      "Encontramos profissionais altamente qualificados para desenvolver o seu negócio.",
    outsourcing_banner_button: "Quero Contratar!",

    // Outsourcing Solutions Section
    outsourcing_solutions_title: "Soluções Outsourcing Starta",
    outsourcing_solutions_description:
      "Foque nas principais atividades do seu negócio e deixe que a gente cuida do resto.\n Veja como a Starta pode te ajudar:",
    outsourcing_solutions_outsourcing_title: "Outsourcing",
    outsourcing_solutions_outsourcing_description:
      "Queremos te ajudar a crescer ainda mais! Encontramos profissionais qualificados que capacitam e impulsionam o seu empreendimento e fazemos a gestão compartilhada deles, mantendo estes profissionais satisfeitos e engajados com o seu negócio.",
    outsourcing_solutions_agilesquads_title: "Squads Ágeis",
    outsourcing_solutions_agilesquads_description:
      "Contrate squads ágeis, equipes autogerenciáveis e multidisciplinares que usam metodologia ágil para entregar resultados precisos e rápidos. Alocamos uma equipe dedicada e pronta para atuar em seus projetos estratégicos com consistência, gestão e visibilidade.",

    // Secondary Banner Section
    outsourcing_secondary_banner_title:
      "Garantimos um processo ágil e eficiente do recrutamento ao acompanhamento dos profissionais. Aceleramos sua transformação digital.",

    // About Solutions Section
    outsourcing_aboutsolutions_title: "Sobre as Soluções",
    outsourcing_aboutsolutions_outsourcing_button: "Outsourcing",
    outsourcing_aboutsolutions_outsourcing_title:
      "Nossos talentos estão preparados para te atender",
    outsourcing_aboutsolutions_outsourcing_description:
      "Para negócios que buscam a transformação digital de forma ágil e com qualidade. Através da nossa metodologia e experiência na área de tecnologia encontramos talentos e alocamos eles no seu negócio, primando pelo encontro da técnica e cultura. Estamos dedicados a proporcionar a melhor experiência ao profissional e ao nosso cliente.",
    outsourcing_aboutsolutions_agilesquads_button: "Squads Ágeis",
    outsourcing_aboutsolutions_agilesquads_title:
      "Alocação de Squads sob demanda",
    outsourcing_aboutsolutions_agilesquads_description:
      "Para negócios que precisam de agilidade, escalabilidade e que não possuem tempo, temos a solução ideal. Entregamos a você toda a solução do outsourcing, mas através de times completos, coesos e experientes.",

    // How We Execute Section
    outsourcing_howweexecute_title: "Como Executamos",
    outsourcing_howweexecute_talentattraction_title: "Atração de Talentos",
    outsourcing_howweexecute_talentattraction_description1:
      "Busca pelo perfil técnico e comportamental ideal para o seu negócio através do RH Especializado;",
    outsourcing_howweexecute_talentattraction_description2:
      "Psicólogos organizacionais experientes na área tech;",
    outsourcing_howweexecute_talentattraction_description3:
      "Para além de soft e hard skills - Buscamos o fit cultural entre Cliente e Profissional.",
    outsourcing_howweexecute_retaining_talent_title: "Retenção de Talentos",
    outsourcing_howweexecute_retaining_talent_description1:
      "Acompanhamento mensal para alinhamento de expectativas e apoio aos profissionais;",
    outsourcing_howweexecute_retaining_talent_description2:
      "Avaliação Semestral e Feedback;",
    outsourcing_howweexecute_retaining_talent_description3:
      "Bônus por Performance;",
    outsourcing_howweexecute_retaining_talent_description4:
      "Incentivos que apoiam a Saúde Mental e Física;",
    outsourcing_howweexecute_retaining_talent_description5:
      "Incentivos à Educação e Aprimoramento Profissional.",
    outsourcing_howweexecute_optimized_management_title: "Gestão Otimizada",
    outsourcing_howweexecute_optimized_management_description:
      "Realizamos a gestão compartilhada dos profissionais. Através de agendas quinzenais com os clientes, alinhamos pontos de melhoria e estamos sempre disponíveis a intermediações necessárias.",

    // Our Methodology Section
    outsourcing_ourmethodology_title: "Nossa Metodologia",
    outsourcing_ourmethodology_subtitle:
      "Cultura forte e gestão centrada nas pessoas",
    outsourcing_ourmethodology_description:
      "Através da gestão centrada nas pessoas e de uma cultura forte que norteia o nosso time, nós desenvolvemos talentos e criamos um ambiente motivador. Nós somos próximos de verdade, acreditamos que promover um ambiente saudável de trabalho, incentivar e capacitar o time é entregar o melhor para os nossos clientes.",
    outsourcing_ourmethodology_culture_description:
      "Cultura Norteadora, Colaboratividade e Pertencimento",
    outsourcing_ourmethodology_followup_description:
      "Acompanhamento e Desenvolvimento de Talentos",
    outsourcing_ourmethodology_management_description:
      "Gestão Humanizada: Nós somos próximos!",

    // Benefits for your business Section
    outsourcing_businessbenefits_title: "Benefícios para o seu negócio",
    outsourcing_businessbenefits_attraction_description:
      "Atração e Recrutamento Eficiente",
    outsourcing_businessbenefits_management_description:
      "Gestão de Pessoas Otimizada - Gestão Compartilhada",
    outsourcing_businessbenefits_reduction_description:
      "Redução do Turnover, Políticas de Reforço e Reconhecimento dos Profissionais",
    outsourcing_businessbenefits_professionals_description:
      "Profissionais Satisfeitos, Motivados e Engajados com as metas do Cliente",

    // Testimonials from our customers Section
    outsourcing_testimonialscustomers_title: "Depoimento de nossos clientes",
    outsourcing_testimonialscustomers_firsttestimonial_description:
      "“É muito bom poder contar com a equipe, porque eles são totalmente disponíveis e flexíveis, não tem tempo ruim.”",
    outsourcing_testimonialscustomers_secondtestimonial_description:
      "“Eles se preocupam em entender sobre o nosso negócio, não vejo diferença entre a equipe interna e o time da Starta, pois eles são interessados e engajados, vestem a camisa.”",
    outsourcing_testimonialscustomers_thirdtestimonial_description:
      "“Essa parceria que temos com a Starta é muito estratégica para nós, pois eles são os profissionais mais importantes do time no momento.”",
    outsourcing_testimonialscustomers_fourthtestimonial_description:
      "“Nunca tive qualquer tipo de problema com os profissionais da Starta, entraram e se encaixaram bem aqui, foi super tranquilo.”",

    // Contact Us Section
    outsourcing_contactus_form_title: "Quer saber mais? Entre em Contato!",
    outsourcing_contactus_faq_title: "FAQ",
    outsourcing_contactus_faq_questionone:
      "Quanto tempo o profissional pode ficar alocado através do Outsourcing?",
    outsourcing_contactus_faq_answerone:
      "Não há um limite de tempo de alocação dos profissionais, o contrato de alocação pode ser de seis meses a um ano e o mesmo pode ser prorrogado por tempo indeterminado.",
    outsourcing_contactus_faq_questiontwo:
      "Até quantos profissionais podem compor uma Squad Ágil?",
    outsourcing_contactus_faq_answertwo:
      "A quantidade de profissionais e suas especialidades serão definidas a partir da demanda do seu desafio, e de acordo com a sua necessidade. Não há uma quantidade determinada, mas sempre há o cuidado de compor uma equipe completa e eficiente para que tenhamos entregas ágeis.",
    outsourcing_contactus_faq_questionthree:
      "Quais são as especialidades profissionais que encontro através das Soluções do Outsourcing e Squads Ágeis da Starta?",
    outsourcing_contactus_faq_answerthree:
      "Nossa equipe conta com profissionais altamente qualificados das diversas áreas do desenvolvimento de software, análise de qualidade, design, UX/UI, gestão de projetos, agilidade, marketing e outros.",
    outsourcing_contactus_faq_button: "Enviar",

    /* JOBS PAGE */

    // Banner Section
    jobs_banner_title:
      "Está pronto para embarcar na jornada da transformação digital?",
    jobs_banner_description:
      "Se você é apaixonado por tecnologia e deseja trabalhar em projetos inovadores, venha fazer parte de um time que acredita que é possível construir um mundo melhor através da tecnologia. ",
    jobs_banner_hashtag: "#VemSerStarta",
    jobs_banner_button: "Nossas vagas",

    // Team work Section
    jobs_teamwork_title: "Aqui, o trabalho em equipe é levado a sério!",
    jobs_teamwork_description:
      "O nosso time é composto por pessoas apaixonadas por inovação e que trabalham juntas por um grande objetivo: facilitar a aplicação de ideias no mundo! Sabemos o quanto a força do poder colaborativo e uma visão otimista são capazes de transformar realidades. Por isso, oferecemos um ambiente dinâmico e flexível, contando sempre com a confiança em cada profissional.",

    // The Starta culture Section
    jobs_startaculture_title: "A Cultura Starta",
    jobs_startaculture_description:
      "Nós somos próximos, valorizamos e reconhecemos os Starters:",
    jobs_startaculture_followup_title:
      "Acompanhamentos e Feedback’s mensais com as lideranças",
    jobs_startaculture_followup_description1: "- Escuta ativa",
    jobs_startaculture_followup_description2: "- Alinhamento de Experiência",
    jobs_startaculture_followup_description3: "- Desenvolvimento de Carreira",
    jobs_startaculture_evaluation_title: "Avaliação Semestral",
    jobs_startaculture_evaluation_description1:
      "- Avaliação Técnica e Comportamental",
    jobs_startaculture_evaluation_description2: "- Bônus por Performance",
    jobs_startaculture_talk_title: "Talk Starta",
    jobs_startaculture_talk_description:
      "Bate-papo semanal com todo o time Starta, onde compartilhamos conhecimento e ficamos por dentro das novidades. Porque aqui caminhamos juntos!",
    jobs_startaculture_coffee_title: "Cafézinho com os Starters",
    jobs_startaculture_coffee_description:
      "Momento de nos conectarmos mais, trocarmos ideias sobre o trabalho, a rotina e o mundo.",
    jobs_startaculture_flexibility_title: "Flexibilidade",
    jobs_startaculture_flexibility_description1: "- Trabalho Remoto ou Híbrido",
    jobs_startaculture_flexibility_description2: "- Horários Flexíveis",
    jobs_startaculture_flexibility_description3: "- Licença remunerada",
    jobs_startaculture_incentives_title: "Incentivos",
    jobs_startaculture_incentives_description1: "- Incentivo Saúde e Educação",
    jobs_startaculture_incentives_description2:
      "- Telemedicina e Seguro de Vida",
    jobs_startaculture_incentives_description3:
      "- Programa Indicação de Sucesso",
    jobs_startaculture_incentives_description4: "- Mimos de Aniversário",

    // Our Mantras Section
    jobs_ourmantras_title: "Nossos Mantras",
    jobs_ourmantras_feedback_description: "Feedbacks são sempre bem-vindos",
    jobs_ourmantras_learn_description:
      "Aprender para ensinar, ensinar para evoluir",
    jobs_ourmantras_health_description: "Sua saúde importa",
    jobs_ourmantras_afraid_description: "Não tenha medo de errar",
    jobs_ourmantras_comunication_description:
      "Comunique-se e esteja aberto a escuta",
    jobs_ourmantras_exercise_description: "Exercite a sua criatividade",
    jobs_ourmantras_freedom_description: "Liberdade com responsabilidade",
    jobs_ourmantras_count_description: "Conte com o time",

    // What our Starters say about us Section
    jobs_aboutus_title: "O que nossos Starters dizem sobre nós",
    jobs_aboutus_description:
      "A Starta me proporciona envolvimento em projetos desafiadores e com soluções inovadoras, que revolucionam o dia-a-dia de diversos negócios e pessoas. É extremamente gratificante poder ver o impacto positivo em negócios e nas pessoas que utilizam as plataformas desenvolvidas por nós.",

    // Linkedin Banner Section
    jobs_linkedinbanner_title:
      "Nos siga no Linkedin e acompanhe as novidades e oportunidades",
    jobs_linkedinbanner_button: "Ir para LinkedIn",

    // Available opportunities Section
    jobs_opportunities_title: "Oportunidades disponíveis",
    jobs_opportunities_empty:
      "No momento não estamos com nenhuma vaga em aberto. Mas fique de olho em nossas redes para acompanhar as novidades e não perder nenhuma oportunidade!",

    // Contact us Section
    jobs_contact_title: "Junte-se a nós e revolucione o mercado de tecnologia",
    jobs_contact_description:
      "Estamos entusiasmados para ter você como parte da nossa equipe. Preencher as informações ao lado é o primeiro passo para uma jornada de sucesso conosco. Sua contribuição será fundamental para o nosso crescimento e realização de objetivos ambiciosos.",

    // Jobs List Section
    jobs_list_title: "Oportunidades disponíveis",

    /* CASES PAGE */

    // Banner Section
    cases_banner_title:
      "Descubra nossos cases de sucesso em soluções de software para empresas",
    cases_banner_description:
      "Oferecemos soluções completas para empresas que desejam transformar o mundo por meio da tecnologia. Nosso portfólio inclui cases de sucesso com grande impacto tecnológico, comprovando nossa experiência e competência em ajudar empresas a alcançarem seus objetivos",

    // Cases Section
    cases_hygia_description:
      "Transforme a sua saúde para uma abordagem preventiva, reativa e preditiva com um aplicativo inovador. Descubra uma forma revolucionária de cuidar da sua saúde, com um sistema integrado que lhe permite monitorar e melhorar sua saúde de maneira mais eficiente e efetiva. Com esta solução em saúde, você terá todas as ferramentas necessárias para manter-se saudável e bem cuidado, a qualquer momento e em qualquer lugar",
    cases_tokyo_description:
      "Plataformas digital que simplificam os serviços de corretores e consultores de negócios, proporcionando maior controle e transparência sobre suas vendas e comissões, e capacitando-os constantemente para evoluir. Com uma experiência do usuário otimizada, nossas soluções digitais ajudam os profissionais a gerenciarem seus negócios com eficiência, permitindo que se concentrem em oferecer um atendimento excepcional aos clientes",
    cases_sicredi_description:
      "Desenvolvemos o seguro Sicredi para painéis solares fotovoltaicos, com o objetivo de proteger os investidores contra imprevistos causados por danos e acidentes inesperados. Com uma abordagem centrada no usuário, nossa solução oferece uma experiência de usuário simplificada e fácil de usar, permitindo que você proteja seu investimento com confiança e tranquilidade.",
    cases_ccg_description:
      "Desenvolvemos um processo de venda de planos de saúde CCG simples e ágil, com a atenção e cuidado que seus clientes merecem. Com a nossa abordagem centrada no usuário, o processo de venda se torna intuitivo e descomplicado, permitindo que você se concentre em fornecer um atendimento personalizado e de qualidade. Com a nossa ajuda, você pode garantir que seus clientes recebam a melhor solução de plano de saúde para suas necessidades, sem precisar passar por um processo de venda estressante ou demorado.",
    cases_anjo_description:
      "Desenvolvida em parceria com o grupo Caburé, irá revolucionar suas vendas de seguros de vida e serviços de saúde. Com este aplicativo inovador, você poderá gerenciar suas vendas de forma ágil, segura e eficiente, garantindo mais sucesso e satisfação para suas necessidades de negócios. Nossa solução foi criada com uma abordagem centrada no usuário, para fornecer uma experiência de usuário impecável e fácil de usar, permitindo que você se concentre em oferecer um atendimento excepcional aos clientes.",
    cases_tecredi_description:
      "Com a plataforma de financiamento de veículos 100% digital, agora é possível comprar e vender carros com segurança e sem burocracia. Nosso aplicativo simplifica todo o processo, tornando a compra ou venda de um veículo uma experiência fácil e tranquila. Com uma abordagem centrada no usuário, nosso aplicativo é intuitivo e simples de usar, proporcionando uma experiência de usuário impecável desde a busca por carros até a conclusão do financiamento. Com nossa ajuda, você pode comprar ou vender seu carro com confiança, sabendo que está fazendo um negócio seguro e sem estresse",

    /* PRODUCTS PAGE */

    // Banner Section
    products_banner_title:
      "Veja alguns dos produtos desenvolvidos pela Starta",
    products_banner_description:
      "Conheça nossas soluções inteligentes desenvolvidas para serem altamente personalizáveis. Potencialize a oferta de serviços e produtos através de nossas plataformas digitais e garanta credibilidade que seu negócio merece.",

    // Products Section
    products_link_button_text: "Saiba mais",
    products_health_title: "Portal de Saúde",
    products_health_description:
      "O Portal de Saúde é uma plataforma que oferece uma gama de soluções relacionadas a vendas de produtos e serviços de saúde. Gerencie e acompanhe todo o processo de venda, desde a simulação, contratação, cadastro, envio de documentos e pagamento. Personalize a plataforma com a identidade de sua empresa e ofereça a seus clientes um atendimento completamente digital.",
    products_open_description:
      "A Open é uma plataforma que promove desafios corporativos de inovação. O objetivo é conectar startups a grandes empresas em busca de soluções inovadoras para desafios empresariais ou desenvolvimento de produtos e serviços inovadores para o mercado nacional.",
    products_insuron_description:
      "A InsurON é a plataforma de venda de Seguros da Starta. Entregamos uma jornada digital, com entrega rápida e de baixo investimento, alavancando e potencializando produtos de seguros em canais digitais. Solução recomendada para corretoras com múltiplos produtos e seguradoras parceiras.",
    products_exohub_description:
      "O EXOHUB é o resultado da colaboração entre a Starta e os Hubs, Coworkings e Laboratórios de Inovação líderes da região Sul do Brasil. Criamos um ecossistema único de troca de conhecimento e experiências entre empresas, empreendedores, educação, networking e criação de novos negócios. Beneficie-se de espaços físicos e virtuais desenvolvidos para impulsionar projetos inovadores e criativos, em uma comunidade em constante crescimento e evolução.",

    /* HEALTH PAGE */

    //Banner Section
    healthProject_banner_title:
      " A Primeira Plataforma de Health Commerce do Brasil",
    healthProject_banner_subtitle:
      "Revolucionando a Distribuição de Serviços de Saúde e Planos através da Tecnologia",
    healthProject_banner_button: "Agende uma demonstração",

    //About Section
    healthProject_about_title:
      "Portal Saúde, uma plataforma sob medida para seu negócio",
    healthProject_about_description:
      " O Portal Saúde é uma plataforma White Label que oferece uma gama de soluções relacionadas a distribuições de serviços de saúde. Gerencie e acompanhe todo o processo de venda, desde a simulação, contratação, cadastro, envio de documentos e pagamento. Personalize a plataforma com a identidade de sua empresa e ofereça a seus clientes uma experiência completamente digital.",

    //Solutions Section
    healthProject_solutions_title: "Nossas soluções",
    healthProject_solutions_healthPlan_title: "Plano de Saúde e Odonto",
    healthProject_solutions_healthPlan_description:
      "Plataforma Whitelabel de simulação e contratação online de Planos de Saúde, Odontológico e Serviços de Saúde Ocupacional. Uma plataforma completa, com o processo totalmente digital.",
    healthProject_solutions_plans_title: "Pacote de Serviços de Saúde",
    healthProject_solutions_plans_description:
      "Exames e check-ups desempenham um papel fundamental na promoção da saúde, prevenção de doenças e no gerenciamento eficaz das condições médicas, contribuindo para uma vida mais saudável e satisfatória. Com nossa plataforma aproximamos organizações desse proposito através de nossa tecnologia.",
    healthProject_solutions_simulator_title: "Simulador de Plano de Saúde",
    healthProject_solutions_simulator_description:
      "O Simulador de Plano de Saúde permite que os consultores criem e encaminhem a proposta comercial para seus clientes diretamente pela plataforma. Permita seus clientes simularem proposta pelo autoatendimento.",
    healthProject_solutions_dps_title: "Declaração Pessoal de Saúde",
    healthProject_solutions_dps_description:
      "A sua declaração de saúde totalmente personalizada e digital, já integrada com um processo inovador CTP (cobertura temporário parcial), combina tecnologia de ponta e processos eficientes para tornar a aceitação de beneficiários em planos de saúde mais ágil e  reduzindo riscos na aceitação.",

    //Demonstration Section
    healthProject_demonstration_title: "Navegue por nossa plataforma",
    healthProject_demonstration_button: "Agende uma Demonstração",

    //Services Section
    healthProject_services_title:
      "Uma plataforma completa para serviços de saúde",
    healthProject_services_description:
      "Soluções altamente personalizáveis ao seu negócio com alta tecnologia e rápida entrega.",
    healthProject_services_text: "Indicado para:",
    healthProject_services_hospitals: "Hospitais",
    healthProject_services_clinics: "Clínicas",
    healthProject_services_healthOperators: "Operadoras de Saúde",
    healthProject_services_creditCooperatives: "Cooperativas de Crédito",
    healthProject_services_financialInstitutions: "Instituições Financeiras",
    healthProject_services_brokerages: "Corretoras",

    //For your business Section
    healthProject_business_title: "Soluções para o seu negócio",
    healthProject_business_checklist_title:
      "Portal Saúde, uma plataforma sob medida para seu negócio",
    healthProject_business_checklist_description:
      "O Portal Saúde é uma plataforma White Label que oferece uma gama de soluções relacionadas a venda de serviços de saúde. Gerencie e acompanhe todo o processo de venda: desde a simulação, contratação, cadastro, envio de documentos e pagamento. Personalize a plataforma com a identidade de sua empresa e ofereça a seus clientes uma experiência completamente digital.",
    healthProject_business_checklist_digital: "Esteira 100% digital",
    healthProject_business_checklist_ai:
      "IA (Inteligência artificial) de Análise de Documentos",
    healthProject_business_checklist_dps: "DPS / CPT digital",
    healthProject_business_checklist_signature: "Assinatura de contrato",
    healthProject_business_checklist_payment:
      "Meios de pagamento (PIX, boleto e cartão)",
    healthProject_business_checklist_broker: "Área do corretor",
    healthProject_business_checklist_lgpd: "Segurança de Dados e LGPD",
    healthProject_business_checklist_tiss: "Padrão TISS",
    healthProject_business_products_title:
      "Promova a transformação digital de seus serviços com o Portal de Saúde:",
    healthProject_business_products_channel:
      "Canal totalmente digital para distribuição de serviços de saúde",
    healthProject_business_products_platform: "Plataforma personalizável",
    healthProject_business_products_clients:
      "Simplificação do processo de aquisição de clientes.",
    healthProject_business_products_efficiency:
      "Aumento da eficiência operacional e gestão centralizada",
    healthProject_business_products_conversion: "Alta conversão de vendas",
    healthProject_business_products_competitive: "Alta competitividade",
    healthProject_business_products_communication:
      "Comunicação eficiente em todas as etapas",

    //Contact Us Section
    healthProject_contact_title:
      "Tem interesse em saber mais sobre o Portal de Saúde?",
    healthProject_contact_description:
      "Nossa equipe especializada está pronta para esclarecer suas dúvidas e oferecer mais informações. Preencha o formulário ao lado que, em breve, entraremos em contato com você.",
    healthProject_contact_form_title: "Entre em contato",
    healthProject_contact_form_name: "Nome",
    healthProject_contact_form_email: "E-mail",
    healthProject_contact_form_phone: "Celular",
    healthProject_contact_form_message: "Mensagem",
    healthProject_contact_form_popup: "Agende uma demonstração",

    //CAROUSEL
    carousel_title: "Cases de Sucesso",
    carousel_button: "Visualizar todos",
    carousel_hygia_title: "Hygia Saúde",
    carousel_hygia_text:
      "Uma forma revolucionária de cuidar da sua saúde, com um sistema integrado que lhe permite monitorar e melhorar sua saúde de maneira mais eficiente e efetiva.",
    carousel_ccg_title: "CCG Saúde",
    carousel_ccg_text:
      "Desenvolvemos um processo de venda de planos de saúde CCG simples e ágil, com a atenção e cuidado que seus clientes merecem.",
    carousel_anjo_title: "Anjo",
    carousel_anjo_text:
      "Desenvolvida em parceria com o grupo Caburé, o Anjo é uma revolução nas vendas de seguros de vida e serviços de saúde.",

    // Software Factory Page

    //Banner Section
    softwarefactory_banner_title: "Fábrica de Software",
    softwarefactory_banner_subtitle:
      "A inovação é nossa linguagem nativa. Desenvolvemos softwares de alto impacto.",
    softwarefactory_banner_button: "Quero Contratar!",

    // About Section
    softwarefactory_about_title:
      "Acelere sua transformação digital com a nossa fábrica de software",
    softwarefactory_about_description:
      "Desenvolvemos softwares personalizados há mais de 7 anos e criamos soluções digitais únicas para nossos clientes. Atuamos desde o entendimento do desafio e concepção da solução, até a implementação e lançamento para o mercado. Somos especialistas em desenvolver soluções para áreas de seguros, saúde e finanças com inovação, eficiência e agilidade.",

    // Our Services Section
    softwarefactory_ourservices_title: "No que podemos te ajudar?",
    softwarefactory_ourservices_description:
      "Desenvolvemos softwares personalizados para atender às necessidades da sua empresa, criamos aplicações e sistemas adaptados aos seus objetivos.",

    softwarefactory_ourservices_card_migrations_title:
      "Migração de sistema legado",
    softwarefactory_ourservices_card_migrations_description:
      "Transferimos processos existentes em sistemas antigos e desatualizados para novas plataformas, modernas e eficientes. Analisamos limitações e pontos de melhoria de sistemas e desenvolvemos novos softwares com arquitetura e tecnologia adequadas às necessidades do negócio.",

    softwarefactory_ourservices_card_software_title:
      "Desenvolvimento de sistemas web e plataformas",
    softwarefactory_ourservices_card_software_description:
      "Quer uma experiência digital única e exclusiva para sua empresa? Oferecemos serviços de desenvolvimento personalizado de sistemas web, sites e plataformas que entregam real valor para seu usuário.",

    softwarefactory_ourservices_card_mobile_title:
      "Desenvolvimento de aplicativos mobile",
    softwarefactory_ourservices_card_mobile_description:
      "Conecte-se com seus usuários de forma eficaz! Criamos aplicativos personalizados para iOS e Android que atendem às necessidades exclusivas de cada cliente.",

    // How we work Section
    softwarefactory_howwework_title: "Como trabalhamos",

    softwarefactory_howwework_planning: "Planejamento",
    softwarefactory_howwework_construction: "Construção",
    softwarefactory_howwework_sustenance: "Sustentação",

    softwarefactory_howwework_planning_challenge_title:
      "Entendimento do desafio",
    softwarefactory_howwework_planning_challenge_description:
      "Essa etapa refere-se ao processo de compreender completamente as necessidades e objetivos do projeto. Envolve análise cuidadosa dos requisitos e restrições do negócio, bem como a identificação de obstáculos técnicos. Por meio de uma abordagem colaborativa, trabalhamos em estreita colaboração com o cliente para garantir que todos os aspectos dos desafios sejam claramente entendidos.",
    softwarefactory_howwework_planning_requirements_title:
      "Mapeamento de requisitos",
    softwarefactory_howwework_planning_requirements_description:
      "Nesta etapa do processo identificamos, documentamos e compreendemos informações relevantes como requisitos funcionais e não funcionais. Essas informações são utilizadas para orientar o desenvolvimento do produto, definindo os recursos, funcionalidades e características que devem ser implementados.",
    softwarefactory_howwework_planning_design_title: "Arquitetura e Design",
    softwarefactory_howwework_planning_design_description:
      "É feita uma análise minuciosa levando em consideração fatores como desempenho, escalabilidade, segurança e usabilidade. Com base nessa análise, são definidas as diretrizes arquiteturais, escolhendo as tecnologias e plataformas mais adequadas para a solução. Em seguida, a equipe de design utiliza essas diretrizes para criar interfaces e experiências de usuário intuitivas e eficientes.",

    softwarefactory_howwework_construction_development_title: "Desenvolvimento",
    softwarefactory_howwework_construction_development_description:
      "Nesta fase, os desenvolvedores utilizam as informações levantadas nas etapas anteriores para criar a estrutura e a lógica do software. São utilizadas linguagens de programação, frameworks e bibliotecas adequadas para o projeto, levando em consideração a eficiência, a escalabilidade e a manutenção do código. Seguimos práticas mais adequadas de codificação, de acordo com o desafio e padrões acordados com o cliente.",
    softwarefactory_howwework_construction_testing_title: "Testes",
    softwarefactory_howwework_construction_testing_description:
      "É feita uma análise minuciosa levando em consideração fatores como desempenho, escalabilidade, segurança e usabilidade. Com base nessa análise, são definidas as diretrizes arquiteturais, escolhendo as tecnologias e plataformas mais adequadas para a solução. Em seguida, a equipe de design utiliza essas diretrizes para criar interfaces e experiências de usuário intuitivas e eficientes.",
    softwarefactory_howwework_construction_homolog_title: "Homologação",
    softwarefactory_howwework_construction_homolog_description:
      "Nessa fase, são realizadas atividades de validação pelo time do cliente para garantir que a solução atenda aos requisitos estabelecidos inicialmente e esteja pronta para uso. Isso envolve uma revisão da documentação técnica, regras de negócio e cenários de testes práticos de uso, a fim de verificar se a solução está completa e precisa.",

    softwarefactory_howwework_sustenance_trainning_title:
      "Treinamento e Documentação",
    softwarefactory_howwework_sustenance_trainning_description:
      "A fase de treinamento e documentação é fundamental para garantir que os sistemas e soluções sejam devidamente utilizados e mantidos pelos usuários e equipe de suporte. Durante essa fase, são desenvolvidos materiais de treinamento, como manuais do usuário, tutoriais e guias de referência, que fornecem instruções detalhadas sobre a operação e funcionalidades dos sistemas.",
    softwarefactory_howwework_sustenance_maintenance_title:
      "Manutenção e Evolução",
    softwarefactory_howwework_sustenance_maintenance_description:
      "Essa etapa compreende as atividades contínuas de suporte, correção de problemas e aprimoramento dos sistemas e soluções implantados. A equipe de suporte técnico monitora e gerencia os sistemas em produção, respondendo a incidentes, identificando e solucionando falhas e garantindo a estabilidade e disponibilidade dos serviços.",

    //Stats Section
    softwarefactory_stats_clients: "Clientes satisfeitos",
    softwarefactory_stats_projects: "Transacionados nas plataformas",
    softwarefactory_stats_users: "De usuários impactados com nossas soluções",

    //Contact Section
    softwarefactory_contact_accordion1_title:
      "Quanto tempo leva para desenvolver um novo projeto?",
    softwarefactory_contact_accordion1_content:
      "O tempo necessário para desenvolver um novo projeto de tecnologia pode variar consideravelmente, dependendo da complexidade do projeto e dos recursos disponíveis. Projetos que levam de 1 mês a 6 meses, trabalhamos com pequenos entregáveis.",
    softwarefactory_contact_accordion2_title:
      "Quais são os benefícios em contratar uma fábrica de software?",
    softwarefactory_contact_accordion2_content:
      "- Expertise Técnica que permite o desenvolvimento de soluções de alta qualidade e com melhores práticas.\n- Redução de custos pois ao contratar uma fábrica de software você evita custos de contratação interna, treinamentos e infraestrutura.\n- Garantia de qualidade e suporte, isso significa que você terá apoio e suporte desde a implementação até a execução do projeto.",
    softwarefactory_contact_accordion3_title:
      "Quais tecnologias são utilizadas nos projetos de desenvolvimento?",
    softwarefactory_contact_accordion3_content:
      "A partir do entendimento do desafio do projeto, selecionamos as tecnologias a serem utilizadas. Podemos citar algumas como Java, React Native, Angular, PHP, Flutter, Python, entre outras.",
    softwarefactory_contact_accordion4_title:
      "Qualquer empresa pode contratar?",
    softwarefactory_contact_accordion4_content:
      "Sim, qualquer empresa que queira inovar e passar por uma transformação digital pode contar com a Starta para o desenvolvimento de tecnologias.",

    softwarefactory_contact_form_title: "Entre em Contato",
    softwarefactory_contact_form_subtitle:
      "Entre em contato para saber mais sobre as nossas soluções. Estamos a disposição.",
    contact_form_name: "Informe seu nome",
    contact_form_email: "Seu e-mail",
    contact_form_phone: "Número de telefone",
    contact_form_message: "Deixe aqui a sua mensagem",
    contact_button_send: "Enviar",
    contact_button_success: "Mensagem enviada!",
    contact_error: "Erro ao enviar mensagem",
    contact_fill_fields: "Preencha todos os campos",
    contact_title: "Entre em contato com a Starta!",
    contact_description:
      "Entre em contato e saiba como a Starta pode te ajudar a alavancar o seu projeto. Nossa equipe está preparada para te atender, tirar todas as dúvidas e iniciar uma parceria de sucesso.",
  },

  [LOCALES.ENGLISH]: {
    /* GLOBAL COMPONENTS */

    // Navbar Component
    navbar_home: "Home",
    navbar_about: "About",
    navbar_products: "Solutions",
    navbar_cases: "Cases",
    navbar_blog: "Blog",
    navbar_careers: "Careers",
    navbar_startaProducts: "Starta Products",
    navbar_softwareFactory: "Software Factory",
    navbar_english: "English",
    navbar_portuguese: "Portuguese",
    navbar_spanish: "Spanish",

    // Footer Component
    footer_home: "Home",
    footer_about: "About",
    footer_products: "Products",
    footer_cases: "Cases",
    footer_blog: "Blog",
    footer_newsletter_title: "Daily updates for your business success",
    footer_newsletter_input: "Enter your email address",
    footer_dataprotection: "Privacy Policy",
    footer_incidentresponse: "Incident Response Plan",
    footer_clientportal: "Client Portal",

    whatsapp_message: "Contact us",

    /* HOME PAGE */

    // Banner Section
    home_banner_title:
      "Uniting creativity and technology to create digital solutions high impact",
    home_banner_description:
      "We are committed to making the digital world a better place, a reality where companies can achieve their goals and people can live their lives more easily and conveniently.",
    home_banner_button: "Starta Products",

    // Stats Section
    home_stats_data_1: "Satisfied Customers",
    home_stats_data_2: "Users on platforms",
    home_stats_data_3: "Active Starters",

    // Soluctions Section
    home_soluctions_title: "Our Solutions",
    home_soluctions_develpment_title: "Development of mobile apps",
    home_soluctions_develpment_text:
      "We create custom apps for iOS and Android devices to help our clients connect with their users effectively.",
    home_soluctions_web_title: "Web systems development",
    home_soluctions_web_text:
      "We offer custom web systems development, including website development, content management systems and web applications",
    home_soluctions_conception_title: "Conception",
    home_soluctions_conception_text:
      "We design customized solutions with our approach approach software design",
    home_soluctions_outsourcing_title: "Outsourcing",
    home_soluctions_outsourcing_text:
      "We empower your business with our outsourced technology services",
    home_soluctions_scan_title: "Scan for businesses",
    home_soluctions_scan_text:
      "Transform your business with our innovative method of digitization and discover new horizons for the growth and success",

    // Clients Section
    home_clients_title: "Clients",

    //Testimonials Section
    home_testimonials_title: "Testimonials from our customers",

    //Contact Section
    home_bannerContact_title: "Accelerate your business success with Starta",
    home_bannerContact_button: "Contact us",

    // Blog Section
    home_blog_button: "View All",

    /* ABOUT PAGE */

    // Banner Section
    about_banner_title:
      "We transform the world through technology and smart solutions",
    about_banner_description:
      "Our company is committed with transformation from technology and collaborative work. We do this through creative and smart solutions that help entrepreneurs materialize and evolve their projects.",

    // Culture Section
    about_culture_title: "Starta Culture",
    about_culture_description:
      "We believe that creating a company where people like to work on is based on relationships, growth and impact. We’ve been acting on tech business for over ten years, seeking to create valuable projects for business and society through team work. ",
    about_culture_mission_title: "Mission",
    about_culture_mission_text:
      "To facilitate companies digital transformation, combining design and innovation to deliver useful and extremely efficient solutions.",
    about_culture_vision_title: "Vision",
    about_culture_vision_text:
      "To be a national reference for solution development in technology, in the insurance field.",
    about_culture_values_title: "Values",
    about_culture_values_text:
      "Responsibility, Commitment, Agility, Ethics, Collaboration, Incentive and Valuation.",

    // Hub Section
    about_hub_title: "Where we are",

    // Testimonials Section
    about_testimonials_title: "What does our Starters say about us",

    /* OUTSOURCING PAGE */

    // Banner Section
    outsourcing_banner_title: "Outsourcing",
    outsourcing_banner_description:
      "We find highly qualified professionals to develop your business.",
    outsourcing_banner_button: "I want to hire!",

    // Outsourcing Solutions Section
    outsourcing_solutions_title: "Outsourcing Starta Solutions",
    outsourcing_solutions_description:
      "Focus on the main activities of your business and let us take care of the rest.\n See how Starta can help you:",
    outsourcing_solutions_outsourcing_title: "Outsourcing",
    outsourcing_solutions_outsourcing_description:
      "We want to help you growing even more! We find qualified professionals that empower and boost your enterprise, and we do their shared management, keeping these professionals satisfied and engaged with your business.",
    outsourcing_solutions_agilesquads_title: "Agile Squads",
    outsourcing_solutions_agilesquads_description:
      "Hire agile squads, self manageable and multidisciplinary teams that use agile methodology to deliver accurate and fast results. We allocate a team who’s dedicated and ready to act in your strategic projects with consistency, management and visibility.",

    // Secondary Banner Section
    outsourcing_secondary_banner_title:
      "We guarantee an efficient agile process since the recruitment to the monitoring of the professionals. We speed up your digital transformation.",

    // About Solutions Section
    outsourcing_aboutsolutions_title: "About the solutions",
    outsourcing_aboutsolutions_outsourcing_button: "Outsourcing",
    outsourcing_aboutsolutions_outsourcing_title:
      "Our talents are prepared to support you",
    outsourcing_aboutsolutions_outsourcing_description:
      "For business that chase the digital transformation with quality and agility. Through our methodology and experience in tech we find talents an allocate them in your business, striving for the encounter between technique and culture. We are dedicated to provide the best experience to the professional and to our customer.",
    outsourcing_aboutsolutions_agilesquads_button: "Agile Squads",
    outsourcing_aboutsolutions_agilesquads_title: "On-demand Squad Allocation",
    outsourcing_aboutsolutions_agilesquads_description:
      "For businesses that need agility, scalability and that don't have time, we have the ideal solution. We deliver the entire outsourcing solution to you, but through complete, cohesive and experienced teams.",

    // How We Execute Section
    outsourcing_howweexecute_title: "How do we do it",
    outsourcing_howweexecute_talentattraction_title: "Talent Attraction",
    outsourcing_howweexecute_talentattraction_description1:
      "Search for the ideal technical and behavioral profile to your business through the specialized HR;",
    outsourcing_howweexecute_talentattraction_description2:
      "Organizational psychologists who are focused in tech;",
    outsourcing_howweexecute_talentattraction_description3:
      "Beyond soft and hard skills - We look for the cultural fit between customer and professional.",
    outsourcing_howweexecute_retaining_talent_title: "Talent Retention",
    outsourcing_howweexecute_retaining_talent_description1:
      "Monthly monitoring for expectation alignment and support to the professionals;",
    outsourcing_howweexecute_retaining_talent_description2:
      "Feedback and semester evaluation;",
    outsourcing_howweexecute_retaining_talent_description3:
      "Performance Bonus;",
    outsourcing_howweexecute_retaining_talent_description4:
      "Physical and Mental Health incentives;",
    outsourcing_howweexecute_retaining_talent_description5:
      "Incentive to Education and Professional Improvement.",
    outsourcing_howweexecute_optimized_management_title: "Optimized Management",
    outsourcing_howweexecute_optimized_management_description:
      "We do the shared management of the professionals. Through fortnight schedule with our customers, we align improvement points and we’re always available to necessary intermediation.",

    // Our Methodology Section
    outsourcing_ourmethodology_title: "Our Methodology",
    outsourcing_ourmethodology_subtitle:
      "Strong culture and people centered culture",
    outsourcing_ourmethodology_description:
      "Through the people centered management and a strong culture that guide our team, we develop talents and create a motivating environment. We are really close and we believe that promoting a healthy work environment, encouraging and empowering the team is to deliver the best to our customers.",
    outsourcing_ourmethodology_culture_description:
      "Guiding Culture, Collaboration and Belonging",
    outsourcing_ourmethodology_followup_description:
      "Follow-up and Talent Development",
    outsourcing_ourmethodology_management_description:
      "Humanized Management: We are close!",

    // Benefits for your business Section
    outsourcing_businessbenefits_title: "Benefits to your busines",
    outsourcing_businessbenefits_attraction_description:
      "Efficient Attraction and Recruitment",
    outsourcing_businessbenefits_management_description:
      "Optimized People Management - Shared Management",
    outsourcing_businessbenefits_reduction_description:
      "Turnover Reduction, Politics of Reinforcement and Acknowledge for the Professionals",
    outsourcing_businessbenefits_professionals_description:
      "Workers who are Satisfied, Motivated and Engaged with the Customer's goals",

    // Testimonials from our customers Section
    outsourcing_testimonialscustomers_title: "Testimony of our customers",
    outsourcing_testimonialscustomers_firsttestimonial_description:
      "“It’s so good to count on the team, because they’re totally available and flexible, there’s no big deal.”",
    outsourcing_testimonialscustomers_secondtestimonial_description:
      "“They worry about our business, I don’t see a difference between the internal team and Starta’s team, they are interested and engaged, they really commit to it.”",
    outsourcing_testimonialscustomers_thirdtestimonial_description:
      "“This partnership we have with Starta is very strategic to us, because they are the most important professionals of the team at the moment.”",
    outsourcing_testimonialscustomers_fourthtestimonial_description:
      "“I never had any kind of problem with the Starta workers, they started and fit in pretty well here, super fine.”",

    // Contact Us Section
    outsourcing_contactus_form_title: "Want to know more? Contact Us!",
    outsourcing_contactus_faq_title: "FAQ",
    outsourcing_contactus_faq_questionone:
      "For how long the professional can stay allocated through Outsourcing?",
    outsourcing_contactus_faq_answerone:
      "There is no time limit for the allocation of professionals, the allocation contract can be from six months to one year and it can be extended indefinitely.",
    outsourcing_contactus_faq_questiontwo:
      "Up to how many workers can compose an Agile Squad?",
    outsourcing_contactus_faq_answertwo:
      "The number of professionals and their specialties will be defined based on the demand for your challenge, and according to your needs. There is no set amount, but care is always taken to compose a complete and efficient team so that we can deliver quickly.",
    outsourcing_contactus_faq_questionthree:
      "Which are the professional specialities that I can find through Outsourcing Solutions and Agile Squads in Starta?",
    outsourcing_contactus_faq_answerthree:
      "Our team has highly qualified professionals from different areas of software development, quality analysis, design, UX/UI, project management, agility, marketing and others.",
    outsourcing_contactus_faq_button: "Send",

    /* JOBS PAGE */

    // Banner Section
    jobs_banner_title: "Are you ready to embark on a digital transformation?",
    jobs_banner_description:
      "If you’re a tech enthusiast and wish to work on innovative projects, come and be part of a team that believes that it's possible to build a better world through technology.",
    jobs_banner_hashtag: "#ComeToStarta",
    jobs_banner_button: "Our Job Opportunities",

    // Team work Section
    jobs_teamwork_title: "Here, teamwork is taken seriously!",
    jobs_teamwork_description:
      "Our team is composed by people who are passionate about innovation and who work together for a bigger goal: to facilitate the application of ideas in the world! We know how much the strength of collaborative power and an optimistic view are capable to transform realities. That 's why we offer a dynamic and flexible environment, always counting on the thrust of each professional.",

    // The Starta culture Section
    jobs_startaculture_title: "Starta Culture",
    jobs_startaculture_description:
      "We are close, we value and recognize our Starters:",
    jobs_startaculture_followup_title:
      "Follow-up and monthly Feedbacks with the leadership",
    jobs_startaculture_followup_description1: "- Active Listening",
    jobs_startaculture_followup_description2: "- Experience Alignment",
    jobs_startaculture_followup_description3: "- Career Development",
    jobs_startaculture_evaluation_title: "Semester Evaluation",
    jobs_startaculture_evaluation_description1:
      "- Technical and Behavioral Evaluation",
    jobs_startaculture_evaluation_description2: "- Performance Bonus",
    jobs_startaculture_talk_title: "Starta Talks",
    jobs_startaculture_talk_description:
      "Weekly Chat with the whole Starta Team, where we share knowledge and stay tuned to the news. Because here we walk together!",
    jobs_startaculture_coffee_title: "Coffee Time with the Starters",
    jobs_startaculture_coffee_description:
      "A moment to connect more, share ideas about the work, the routine and the world.",
    jobs_startaculture_flexibility_title: "Flexibility",
    jobs_startaculture_flexibility_description1: "- Remote or Hybrid Job",
    jobs_startaculture_flexibility_description2: "- Flexible Schedules",
    jobs_startaculture_flexibility_description3: "- Paid Leave",
    jobs_startaculture_incentives_title: "Incentive",
    jobs_startaculture_incentives_description1:
      "- Health and Education Incentive",
    jobs_startaculture_incentives_description2:
      "- Telemedicine and Life Insurance",
    jobs_startaculture_incentives_description3:
      "- Indication of Success Program",
    jobs_startaculture_incentives_description4: "- Birthday Treats",

    // Our Mantras Section
    jobs_ourmantras_title: "Our Mantras",
    jobs_ourmantras_feedback_description: "Feedbacks are always welcome",
    jobs_ourmantras_learn_description: "Learn to teach, teach to evolve",
    jobs_ourmantras_health_description: "Your health matters",
    jobs_ourmantras_afraid_description: "Don’t be afraid to make a mistake",
    jobs_ourmantras_comunication_description:
      "Communicate and be open to listen",
    jobs_ourmantras_exercise_description: "Exercise your creativity",
    jobs_ourmantras_freedom_description: "Freedom with responsibility",
    jobs_ourmantras_count_description: "Count on the team",

    // What our Starters say about us Section
    jobs_aboutus_title: "What our Starters say about us",
    jobs_aboutus_description:
      "Starta provides me involvement in challenging projects and with innovating solutions, that revolutionize several people and businesses day-to-day. It 's extremely gratifying to be able to see the positive impact in the business and the people who use the platforms developed by us.",

    // Linkedin Banner Section
    jobs_linkedinbanner_title:
      "Follow us on LinkedIn and watch the news and opportunities",
    jobs_linkedinbanner_button: "Go to LinkedIn",

    // Available opportunities Section
    jobs_opportunities_title: "Available Opportunities",
    jobs_opportunities_empty:
      "We currently do not have any vacancies open. But keep an eye on our networks to keep up with the news and not miss any opportunities!",

    // Contact us Section
    jobs_contact_title: "Join us and revolutionize the tech market",
    jobs_contact_description:
      "We are excited to have you as a part of our team. Filling the information on the side is the first step to a successful journey with us. Your contribution will be fundamental to our growth and to accomplish ambitious goals.",

    /* JOBS PAGE */

    // Jobs List Section
    jobs_list_title: "Available Opportunities",

    /* CASES PAGE */

    // Banner Section
    cases_banner_title:
      "See our success cases in software solutions for business",
    cases_banner_description:
      "We offer complete solutions for companies that wish to transform the world through technology. Our portfolio includes success cases with big technologic impact, proving our competence on helping companies to reach their goals",

    cases_hygia_description:
      "Transform your health into a preventive, reactive and predictive approach with an innovative app. Discover a revolutionary way to handle your care, with an integrated system that allows you monitoring and improving your health the most effective way. With this solution in health, you will have all the necessary tools to keep yourself healthy and safe, at any moment and anywhere",
    cases_tokyo_description:
      "Digital platforms that simplify broker and business consultant services, providing bigger control and transparency about your commissions and sales, and empowering them constantly to evolve. With an optimized user experience, our digital solutions help the professional to manage their business efficiently, allowing them to focus on provide an exceptional customer service",
    cases_sicredi_description:
      "We developed Sicredi photovoltaic panels insurance, with the goal to protect investors against unforeseen caused by damage and unexpected accidents. With an user centered approach, our solution offers a simplified and user-friendly UX, allowing you to protect your investment with trust and tranquility.",
    cases_ccg_description:
      "We developed a simple and fast CCG health insurance selling process, with the attention and care that your customers deserve. With our user centered approach, the selling process becomes intuitive and hassle-free, allowing you to concentrate into provide a high quality and personalized service. With our help, you can ensure that your customers receive the best health plan solution for their necessities, without needing to pass through a stressful or time-consuming selling process",
    cases_anjo_description:
      "Developed in partnership with Caburé group, it will revolutionize your health service and life insurance sellings. With this innovative app, you will be able to manage your sales in a fast, safe and efficient way, ensuring further success and satisfaction to your business necessities. Our solution was created with a user-centered approach, to provide a flawless and user-friendly UX, allowing you to concentrate on offering exceptional customer service.",
    cases_tecredi_description:
      "With the 100% digital vehicle financing platform, now it is possible to buy and sell cars with safety and without bureaucracy. Our app simplifies all the process, turning a vehicle purchase or sale an easy and smooth experience. With a user centered approach, our app is intuitive and simple to use, providing a flawless user experience since the car searching to the financing conclusion. With our help, you can buy or sell your car with trust, knowing that you’re doing a safe and unstressed business.",

    /* PRODUCTS PAGE */

    // Banner Section
    products_banner_title: "See some of the products developed by Starta",
    products_banner_description:
      "Know our smart solutions developed to be highly customizable. Potentialize service and product offers through our digital platforms and ensure the credibility your business deserves.",

    // Products Section
    products_link_button_text: "Know more",
    products_health_title: "Health Portal",
    products_health_description:
      "The Health Portal is a platform that offers a range of sale related solutions for health products and services. Manage and go along with all the selling process, since the simulation, contracting, register, sending documents and payment. Customize the platform with your company’s identity and offer your customers completely digital service.",
    products_open_description:
      "Open is a platform that promotes innovative corporate challenges. The goal is to connect startups to big companies in search of innovative solutions for business challenges or development of innovative products and services for the national market.",
    products_insuron_description:
      "InsurON is the Starta Ideia platform to insurance sales. We deliver a digital journey, with a fast and low investment deliver, leveraging and potentializing insurance products in digital channels. Solution recommended for brokers with multiple products and partner insurers.",
    products_exohub_description:
      "EXOHUB is the result of the collaboration between Starta and Hubs, Coworkings and Innovation Labs which are leader in South Brazil. We created a unique ecosystem for trade of knowledge between companies, entrepreneurs, education, networking and creation of new business. Benefit from physical and virtual spaces developed to boost innovative and creative projects, in a community under constant growth and evolution.",

    /* HEALTH PAGE */

    //Banner Section
    healthProject_banner_title: "The First Health Commerce Platform in Brazil",
    healthProject_banner_subtitle:
      "Revolutionizing the Distribution of Health Services and Plans through Technology",
    healthProject_banner_button: "Schedule a demo",

    //About Section
    healthProject_about_title:
      "Health Portal, a tailored platform for your business",
    healthProject_about_description:
      "Health Portal is a White Label platform that offers a range of solutions related to the distribution of health services. Manage and track the entire sales process, from simulation, contracting, registration, document submission to payment. Customize the platform with your company's identity and provide your customers with a completely digital experience.",

    //Solutions Section
    healthProject_solutions_title: "Our Solutions",
    healthProject_solutions_healthPlan_title: "Health and Dental Plans",
    healthProject_solutions_healthPlan_description:
      "White-label platform for online simulation and contracting of Health, Dental Plans, and Occupational Health Services. A complete platform with a fully digital process.",
    healthProject_solutions_plans_title: "Health Services Package",
    healthProject_solutions_plans_description:
      "Exams and check-ups play a crucial role in promoting health, preventing diseases, and effectively managing medical conditions, contributing to a healthier and more satisfying life. With our platform, we bring organizations closer to this purpose through our technology.",
    healthProject_solutions_simulator_title: "Health Plan Simulator",
    healthProject_solutions_simulator_description:
      "The Health Plan Simulator allows consultants to create and submit commercial proposals to their clients directly through the platform. Enable your clients to simulate proposals through self-service.",
    healthProject_solutions_dps_title: "Personal Health Declaration",
    healthProject_solutions_dps_description:
      "Your fully customized and digital health statement, already integrated with an innovative Temporary Partial Coverage (CTP) process, combines cutting-edge technology and efficient processes to expedite the acceptance of beneficiaries into health plans and reduce acceptance risks.",

    //Demonstration Section
    healthProject_demonstration_title: "Navigate through the platform",
    healthProject_demonstration_button: "Schedule a demonstration",

    //Services Section
    healthProject_services_title: "A complete platform for health services",
    healthProject_services_description:
      "Highly customizable solutions for your business with advanced technology and fast delivery.",
    healthProject_services_text: "Suitable for:",
    healthProject_services_hospitals: "Hospitals",
    healthProject_services_clinics: "Clinics",
    healthProject_services_healthOperators: "Healthcare Providers",
    healthProject_services_creditCooperatives: "Credit Cooperatives",
    healthProject_services_financialInstitutions: "Financial Institutions",
    healthProject_services_brokerages: "Insurance Brokers",

    //For your business Section
    healthProject_business_title: "Solutions for your business",
    healthProject_business_checklist_title:
      "Health Portal, a tailored platform for your business",
    healthProject_business_checklist_description:
      "Health Portal is a White Label platform that offers a range of solutions related to the sale of health services. Manage and track the entire sales process: from simulation, contracting, registration, document submission to payment. Customize the platform with your company's identity and offer your customers a completely digital experience.",
    healthProject_business_checklist_digital: "100% Digital Pipeline",
    healthProject_business_checklist_ai:
      "AI (Artificial Intelligence) Document Analysis",
    healthProject_business_checklist_dps: "Digital DPS / CPT",
    healthProject_business_checklist_signature: "Contract Signing",
    healthProject_business_checklist_payment:
      "Payment Methods (PIX, boleto, and card)",
    healthProject_business_checklist_broker: "Broker Area",
    healthProject_business_checklist_lgpd: "Data Security and LGPD Compliance",
    healthProject_business_checklist_tiss: "TISS Standard",
    healthProject_business_products_title:
      "Promote the digital transformation of your services with the Health Portal:",
    healthProject_business_products_channel:
      "Fully digital channel for the distribution of health services",
    healthProject_business_products_platform: "Customizable platform",
    healthProject_business_products_clients:
      "Simplification of the customer acquisition process",
    healthProject_business_products_efficiency:
      "Increased operational efficiency and centralized management",
    healthProject_business_products_conversion: "High sales conversion",
    healthProject_business_products_competitive: "High competitiveness",
    healthProject_business_products_communication:
      "Effective communication at all stages",

    //Contact Us Section
    healthProject_contact_title:
      "Interested in learning more about the Health Portal?",
    healthProject_contact_description:
      "Our specialized team is ready to answer your questions and provide more information. Fill out the form on the side, and we will contact you soon.",
    healthProject_contact_form_title: "Contact Us",
    healthProject_contact_form_name: "Name",
    healthProject_contact_form_email: "E-mail",
    healthProject_contact_form_phone: "Phone Number",
    healthProject_contact_form_message: "Message",
    healthProject_contact_form_popup: "Schedule a demo",

    //Carousel
    carousel_title: "Success Cases",
    carousel_button: "View all",
    carousel_hygia_title: "Hygia Health",
    carousel_hygia_text:
      "A revolutionary way to take care of your health, with an integrated system that allows you to monitor and improve your health in a more efficient and effective way.",
    carousel_ccg_title: "CCG Saúde",
    carousel_ccg_text:
      "We have developed a simple and agile CCG health plan sales process, with the attention and care that your customers deserve.",
    carousel_anjo_title: "Anjo",
    carousel_anjo_text:
      "Developed in partnership with the Caburé group, Anjo is a revolution in the sales of life insurance and health services.",

    // Software Factory Page

    //Banner Section
    softwarefactory_banner_title: "Software Factory",
    softwarefactory_banner_subtitle:
      "Innovation is our native language. We develop high impact software.",
    softwarefactory_banner_button: "I Want to Hire!",

    // About Section
    softwarefactory_about_title:
      "Speed up your digital transformation with our software factory",
    softwarefactory_about_description:
      "We develop custom softwares since more than 7 years ago and we create unique digital solutions to our customers. We operate since the understanding of the challenge and the conception of the solution until the implementation and launching to the market. We are specialists in developing solutions to insurance, health and finance areas, with efficiency and agility.",

    // Our Services Section
    softwarefactory_ourservices_title: "How can we help you?",
    softwarefactory_ourservices_description:
      "We develop custom softwares to attend to your company 's necessities, create applications and systems that are adapted to your goals.",

    softwarefactory_ourservices_card_migrations_title:
      "Migration of legacy system",
    softwarefactory_ourservices_card_migrations_description:
      "We transfer existing processes in former and outdated systems to new, modern and efficient platforms. We analyze system ‘s limitations and improvement points and we develop new softwares with architecture and technology that are suitable to the business necessities.",

    softwarefactory_ourservices_card_software_title:
      "Web systems and app development",
    softwarefactory_ourservices_card_software_description:
      "Do you want a unique and exclusive digital experience to your company? We offer services of  custom development for web systems, sites and platforms that deliver real value to your user.",

    softwarefactory_ourservices_card_mobile_title: "Mobile apps development",
    softwarefactory_ourservices_card_mobile_description:
      "Connect to your users in an efficient way! We create custom apps for iOS and Android that serve the exclusive necessities of each customer.",

    // How we work Section
    softwarefactory_howwework_title: "How do we work",

    softwarefactory_howwework_planning: "Planning",
    softwarefactory_howwework_construction: "Construction",
    softwarefactory_howwework_sustenance: "Support",

    softwarefactory_howwework_planning_challenge_title:
      "Understanding of the challenge",
    softwarefactory_howwework_planning_challenge_description:
      "This stage refers to the process of completely understanding the necessities and goals of the project. It involves careful analysis of the requirements and restrictions of the business, just as the identification of the technical obstacles. Through a collaborative approach, we work in straight collaboration with the customer to make sure that every aspect of the challenges are clearly understood.",
    softwarefactory_howwework_planning_requirements_title:
      "Requirement mapping",
    softwarefactory_howwework_planning_requirements_description:
      "At this stage of the process we identify, document and comprehend relevant data like functional and non-functional requirements. Those information are used to guide the development of the product, defining the resources, functionalities and characteristics that must be implemented.",
    softwarefactory_howwework_planning_design_title: "Design and Architecture",
    softwarefactory_howwework_planning_design_description:
      "A careful analysis is done taking into consideration factors like performance, scalability, security and usability. Based on this analysis, the architectural guideline are defined, choosing the most adequate platforms and technologies to the solution. After this, the design team uses those guidelines to create intuitive and efficient user experience and interface.",

    softwarefactory_howwework_construction_development_title: "Development",
    softwarefactory_howwework_construction_development_description:
      "At this stage, the developers use the information collected in the previous stages to create the structure and the logic of the software. Programming languages, frameworks and data libraries that are adequate to the project , taking into consideration the efficiency, the scalability and the code maintenance. We follow the most adequate coding practices, according to the challenge and the stakes accorded with the customer.",
    softwarefactory_howwework_construction_testing_title: "Testing",
    softwarefactory_howwework_construction_testing_description:
      "At this stage, series of tests are conducted to evaluate the quality, functionality and performance of the solution. The quality assurance team uses proper techniques and tools to plan, execute and register the results of the tests. This stage is essential to guarantee the quality and the reliability of the solution.",
    softwarefactory_howwework_construction_homolog_title: "Homologation",
    softwarefactory_howwework_construction_homolog_description:
      "At this stage, validation activities are carried out by the customer ‘s team to guarantee that the solution reaches the requirements established at the beginning and that it 's ready to use. That involves a revision of the technical documentation, business rule and usability test cases, aiming to verify if the solution is complete and precise.",

    softwarefactory_howwework_sustenance_trainning_title:
      "Training and Documentation",
    softwarefactory_howwework_sustenance_trainning_description:
      "The training and documentation stage is fundamental to make sure that the systems and solutions are properly used and maintained by the users and the support team. During this stage, training materials are developed, such as user manuals, tutorials and reference guides, that provide detailed instructions about the operation and the system's functionalities.",
    softwarefactory_howwework_sustenance_maintenance_title:
      "Maintenance and Evolution",
    softwarefactory_howwework_sustenance_maintenance_description:
      "This stage comprehends the continuous support activities, bug fixes and improvement of the systems and solutions deployed. The technical support team tracks and manages the systems in production, answering in incidents, identifying and solving flaws and ensuring the services availability and stability.",

    //Stats Section
    softwarefactory_stats_clients: "Satisfied clients",
    softwarefactory_stats_projects: "Transacted on platforms",
    softwarefactory_stats_users: "Of users impacted for our solutions",

    //Contact Section
    softwarefactory_contact_accordion1_title:
      "How long does it take to develop a new project?",
    softwarefactory_contact_accordion1_content:
      "The needed time to develop a new technology project may vary considerably, depending on the complexity of the project and the resources available. Projects that take from 1 to 6 months, we work with small deliverables",
    softwarefactory_contact_accordion2_title:
      "Which are the benefits in hiring a software factory?",
    softwarefactory_contact_accordion2_content:
      "- Technical expertise that allows the development of high quality solutions and with better practices. \n- Cost reduction, because when you hire a software factory you avoid intern hiring, training and infrastructure costs. \n- Support and Quality Assurance, that means you will have help and support from the implementation until the execution of the project.",
    softwarefactory_contact_accordion3_title:
      "Which technologies are used in the development projects?",
    softwarefactory_contact_accordion3_content:
      "From the understanding of the challenge in the project, we choose the technologies to be used. We can mention some like Java, React Native, Angular, PHP, Flutter, Python, among others",
    softwarefactory_contact_accordion4_title: "Any company can hire?",
    softwarefactory_contact_accordion4_content:
      "Yes, any company that wants to innovate and pass through a digital transformation can count on Starta to the development of technologies.",

    softwarefactory_contact_form_title: "Contact Starta!",
    softwarefactory_contact_form_subtitle:
      "Get in touch and know how Starta can help you leverage your project. Our team is ready to serve you, take all your doubts and begin a successful partnership.",
    contact_form_name: "Insert your name",
    contact_form_email: "Your e-mail",
    contact_form_phone: "Phone Number",
    contact_form_message: "Leave your message here",
    contact_button_send: "Send",
    contact_button_success: "Message sent!",
    contact_error: "Error sending message",
    contact_fill_fields: "Fill in all fields",
    contact_title: "Get in touch with Starta!",
    contact_description:
      "Get in touch and find out how Starta can help you boost your project. Our team is prepared to assist you, answer all your questions and start a successful partnership.",
  },
};
