function PT() {
  return (
    <div>
      <h2>POLÍTICA DE PRIVACIDADE E PROTEÇÃO DE DADOS</h2>
      <h3>Seja bem-vindo(a)!</h3>
      <section>
        <p>
          A seguir, compartilhamos informações importantes para que você possa
          entender como é feito o tratamento dos seus dados pessoais. Boa
          leitura!
        </p>
        <p>
          A STARTA é uma empresa que atua na geração de negócios na área da
          tecnologia através de soluções criativas e inteligentes, com o
          objetivo de auxiliar empreendedores a materializar ideias e
          transformá-las em grandes projetos.
        </p>
        <p>
          A presente Política de Privacidade e Proteção de Dados tem por
          finalidade regulamentar o tratamento de dados pessoais de nossos
          Clientes, Prestadores de Serviços, Parceiros Comerciais e
          Colaboradores.
        </p>
        <p>
          Ao definir as práticas que regem as nossas atividades, assumimos o
          compromisso e a responsabilidade de respeitar e observar a Lei Geral
          de Proteção de Dados (Lei no 13.709/18) e demais leis aplicáveis sobre
          a matéria, e nos comprometemos a adotar as melhores práticas para
          garantir que os seus dados pessoais sejam tratados de forma adequada e
          segura.
        </p>
        <p>
          As disposições desta Política de Privacidade aplicam-se à{" "}
          <b> STARTA TECNOLOGIA DA INFORMAÇÃO LTDA. (STARTA)</b>,
          pessoa jurídica de direito privado, inscrita no CNPJ sob o no
          16.713.376/0001-83, com sede na Rua Candido Silveira, no 198, Sala
          304, no município Porto Alegre - RS, CEP 95.540-010.
        </p>
        <p>
          Para melhor compreensão sobre as operações de tratamento de dados
          realizada pela <b>STARTA</b>, recomendamos que você “Usuário”
          leia atentamente os termos desta “Política”, inclusive para fins de
          validar o seu consentimento em relação aos termos de privacidade e
          proteção de dados pessoais que lhe diga respeito, nos termos do artigo
          7o, inciso I e artigo 33, incisos VIII e IX, da Lei no 13.709/2018
          (Lei Geral de Proteção de Dados Pessoais - LGPD). No caso de
          discordância com a nossa Política de Privacidade e Termos de Uso, o
          usuário deverá se abster de utilizar os nossos serviços.
        </p>
      </section>
      <section>
        <h4>1. DO TRATAMENTO DE DADOS PESSOAIS</h4>
        <p>
          Consideram-se dados pessoais toda a informação relativa às pessoas,
          que possa ser identificada, direta ou indiretamente, por meio de
          informações como: nome, dados de localização, identificação por meios
          eletrônicos (e-mail, IP, smartphones) ou outras características
          específicas que venham a viabilizar a identificação individual do
          titular de dados.
        </p>
        <p>
          Toda informação pessoal identificável que você nos fornece será usada
          somente de acordo com o permitido por lei, incluindo o necessário para
          oferecer o produto, serviço ou informação solicitada por você.
        </p>
        <p>
          Seus dados pessoais não serão vendidos ou alugados e o
          compartilhamento, se houver, se limita a nossos parceiros de negócios
          conforme requerido na execução de nossos serviços ou conforme exigido
          por leis e regulamentações, caso em que a STARTA adota todas as
          medidas necessárias para assegurar que os subcontratados satisfazem os
          requisitos legais aplicáveis e oferecem as garantias adequadas para a
          proteção dos dados.
        </p>
      </section>
      <section>
        <h4>2. QUAIS DADOS COLETAMOS:</h4>
        <p>
          Caso o “Usuário” tenha interesse em nos contatar por este site, serão
          solicitados os seguintes dados pessoais: nome, telefone, e-mail.
        </p>
        <p>
          Para a realização de nossas atividades e execução de contratos para o
          desenvolvimento de programas de computador customizáveis e não
          customizáveis, suporte técnico e outros serviços de informática, serão
          coletados, entre outras informações, os seguintes dados pessoais: nome
          do contratante e/ou do representante legal da pessoa jurídica, CPF,
          telefone, e-mail, registro profissional.
        </p>
        <p>
          Para recrutamento e seleção de pessoal serão coletados, inicialmente,
          nome, endereço residencial, endereço eletrônico e outras informações
          fornecidas pelo candidato à vaga em seu currículo vitae.
        </p>
        <p>
          Nas hipóteses em que o titular de dados pessoais participar de eventos
          ou webinars realizados pela Starta, realizando a sua inscrição
          por intermédio de plataformas on-line de serviços, é importante
          observar que, além dos Termos desta Política, também serão aplicadas
          as Políticas de Privacidade e os Termos de Uso do Agente/Promotor do
          Eventos (Operador).
        </p>
        <p>
          Portanto, é responsabilidade do usuário ler os termos da Política de
          Privacidade do Agente/Promotor de Eventos, para manifestar seu
          consentimento sobre o tratamento de seus dados pessoais. Caso seja
          necessário, a STARTA poderá solicitar outros dados pessoais, a
          fim de confirmar a sua identidade e a sua correspondência com os dados
          inseridos na contratação dos serviços, e esta solicitação poderá se
          dar por telefone, e-mail, WhatsApp.
        </p>
      </section>
      <section>
        <h4>3. FUNDAMENTO PARA O TRATAMENTO DE DADOS:</h4>
        <p>
          No exercício das atividades da STARTA, o tratamento de dados
          pessoais enquadra-se na execução e cumprimento de contrato; no
          cumprimento de obrigações legais e regulatórias, no legítimo interesse
          da empresa, e nos casos em que for exigido, mediante o fornecimento de
          consentimento pelo titular, sendo assegurados aos titulares dos dados
          pessoais os direitos previstos no artigo 18 da Lei Geral de Proteção
          de Dados Pessoais (Lei no 13.709/18).
        </p>
        <p>
          Os dados pessoais dos usuários podem ser utilizados para fins
          jurídicos pelo controlador de dados quando demandado em juízo ou
          quando necessário para ajuizamento de possível ação jurídica
          decorrente de uso indevido deste serviço ou de serviços a ele
          relacionados.
        </p>
        <p>
          A STARTA poderá revelar os dados pessoais sempre que houver
          solicitação do Poder Público ou Autoridade Judicial para cumprimento
          de obrigação legal.
        </p>
        <p>
          O controlador dos dados poderá ainda revelar os dados pessoais
          mediante solicitação do poder público ou para cumprimento de obrigação
          legal.
        </p>
      </section>
      <section>
        <h4>4. COM QUEM COMPARTILHAMOS SEUS DADOS:</h4>
        <p>
          No âmbito de nossas atividades empresariais e das relações comerciais,
          informações relacionadas ao nome da empresa, setor, cargo,
          departamento, função e dados pessoais dos representantes legais e de
          seus funcionários poderão ser compartilhadas com parceiros de negócios
          e colaboradores, para a execução de contrato ou cumprimento de
          obrigação legal.
        </p>
        <p>
          Prestadores de Serviços da STARTA também terão acesso e poderão
          compartilhar as informações e os dados dos titulares processados,
          estando orientados para que o compartilhamento dos dados pessoais se
          dê exclusivamente para os fins necessários e de conformidade com a Lei
          Geral de Proteção de Dados e demais normas e determinações de órgãos
          reguladores.
        </p>
        <p>
          Em caso de venda, fusão ou transferência da totalidade ou de parte dos
          nossos negócios (inclusive em caso de reorganização, dissolução,
          liquidação ou outra reestruturação societária), podemos divulgar os
          seus dados pessoais ao potencial vendedor ou comprador ou aos seus
          representantes, desde que adotadas as devidas medidas para garantir a
          segurança e a confidencialidade das informações. Seus dados NÃO serão
          utilizados para outras finalidades como, por exemplo, compartilhamento
          de informações e listas de e-mails para comercialização.
        </p>
      </section>
      <section>
        <h4>5. TRANSFERÊNCIA INTERNACIONAL DE DADOS</h4>
        <p>
          Para a realização e desenvolvimento de projetos e soluções inovadoras
          na área da tecnologia, a STARTA poderá realizar a transferência
          internacional de dados pessoais, em conformidade com o artigo 33,
          inciso IX, da Lei 13.709/2018, ou seja, quando necessário para a
          execução de contrato ou de procedimentos preliminares relacionados a
          contrato do qual seja parte o titular, a pedido do titular dos dados;
          para o cumprimento de obrigação legal ou regulatória pelo controlador
          ou para o exercício regular de direitos em processo judicial,
          administrativo ou arbitral.
        </p>
        <p>
          No compartilhamento de dados pessoais com países estrangeiros,
          adotamos as melhores práticas de segurança da informação, buscando
          proporcionar aos nossos clientes e titulares de dados pessoais o grau
          de sigilo e de proteção adequado ao previsto na LGPD, sendo, para
          estes casos, solicitado o Consentimento do titular de dados.
        </p>
      </section>
      <section>
        <h4>6. DE QUE FORMA OS SEUS DADOS SÃO COLETADOS?</h4>
        <ul>
          <li>Dados Cadastrais;</li>
          <li>Website;</li>
          <li>Correio eletrônico;</li>
          <li>Seleção e recrutamento de colaboradores;</li>
          <li>Webinars e eventos on-line;</li>
          <li>
            Através de ações tomadas pelo titular nos perfis da empresa nas
            redes sociais, tais como curtidas, comentários ou similares;
          </li>
          <li>
            Quando disponibilizadas publicamente, incluindo, mas não se
            limitando a, mídias sociais (como, Facebook, Instagram e LinkedIn).
          </li>
        </ul>
        <p>
          Alguns dados pessoais coletados são de preenchimento obrigatório e
          necessários para a execução e realização de nossas atividades, sendo
          que, em caso de falta ou insuficiência desses dados, a STARTA se
          resguarda no direito de não prestar os serviços ou as informações
          solicitadas. Em cada caso, informaremos aos titulares sobre a natureza
          obrigatória do fornecimento destes dados pessoais e a finalidade de
          sua utilização.
        </p>
        <p>
          Esclarecemos que as interações dos usuários com nossos perfis em redes
          sociais são regidas pelas políticas de privacidade e termos de uso das
          respectivas plataformas.
        </p>
      </section>
      <section>
        <h4>7. DURAÇÃO DO TRATAMENTO</h4>
        <p>
          A duração do tratamento de dados ocorrerá por tempo indeterminado,
          garantindo-se o término do tratamento nas seguintes hipóteses:
        </p>
        <ul>
          <li>
            Verificação de que a finalidade foi alcançada ou de que os dados
            deixaram de ser necessários ou pertinentes ao alcance da finalidade
            específica para a qual foram coletados;
          </li>
          <li>
            Comunicação do titular, inclusive no exercício de seu direito de
            revogação do consentimento, resguardado o interesse público; ou
          </li>
          <li>
            Determinação da Autoridade Nacional, quando houver violação a
            disposições legais.
          </li>
        </ul>
      </section>
      <section>
        <h4>8. ARMAZENAMENTO</h4>
        <p>
          Os dados pessoais são armazenados em ambientes seguros da nossa
          infraestrutura de TI e fornecedores de serviços de tratamento de dados
          em nuvem que foram previamente avaliados em relação à segurança dos
          dados.
        </p>
        <p>
          Além destas medidas técnicas e de segurança da informação, a
          STARTA adota medidas administrativas para gestão de riscos junto
          aos Colaboradores e Subcontratados, que contemplam a formalização de
          Contratos, Regulamentos de Proteção de Dados, Termos de
          Responsabilidade, Códigos de Conduta, entre outros, com o objetivo de
          orientar os colaboradores acerca de sua conduta no que tange à
          proteção e privacidade de dados pessoais.
        </p>
        <p>
          Nos termos do item 8, não havendo necessidade de guarda, os dados
          pessoais serão eliminados após o término de seu tratamento. Porém,
          serão conservados aqueles dados pessoais que se destinem ao
          cumprimento de obrigação legal ou regulatória por parte da
          STARTA, ou que sejam destinados ao seu uso exclusivo, assegurada
          a anonimização dentro dos limites técnicos possíveis.
        </p>
      </section>
      <section>
        <h4>9. DIREITOS DO TITULAR DOS DADOS PESSOAIS:</h4>
        <p>
          A STARTA reconhece que todas as pessoas têm direito a proteção
          dos dados de caráter pessoal e assegura aos titulares o exercício dos
          direitos previstos na Lei no 13.709/2018, dentre eles o direito de
          obter, mediante requisição à Controladora:
        </p>
        <ul>
          <li>Confirmação da existência de tratamento;</li>
          <li>Acesso aos dados;</li>
          <li>Correção de dados incompletos, inexatos ou desatualizados;</li>
          <li>
            Anonimização, bloqueio ou eliminação de dados desnecessários,
            excessivos ou tratados em desconformidade com o disposto na Lei no
            13.709/2018;
          </li>
          <li>
            Portabilidade dos dados a outro fornecedor de serviços ou produto;
          </li>
          <li>
            Eliminação dos dados pessoais tratados com o consentimento do
            titular, exceto nas hipóteses previstas no art. 16 Lei no 13.
            709/2018;
          </li>
          <li>
            Informação das entidades públicas e privadas com os quais a
            Controladora realizou uso compartilhado de dados;
          </li>
          <li>
            Informação sobre a possibilidade de não fornecer consentimento e
            sobre as consequências da negativa;
          </li>
          <li>
            Revogação do consentimento, nos termos do §5o do art. 8 da Lei no
            13.709/2018.
          </li>
        </ul>
        <p>
          O exercício dos direitos descritos acima, reclamações ou outras
          comunicações, deverão ser realizados mediante requerimento expresso do
          titular ou de representante legalmente constituído, e enviados à
          STARTA através dos canais de contato informados ao final desta
          Política.
        </p>
      </section>
      <section>
        <h4>10. RESPONSABILIDADES</h4>
        <p>
          A STARTA não será responsabilizada por danos que decorram do
          tratamento inadequado de dados tornados públicos pelo próprio titular,
          assim como também não será responsabilizada, dentre outras hipóteses:
        </p>
        <ul>
          <li>
            Se o dano for decorrente de culpa exclusiva do titular ou de
            terceiros; ou
          </li>
          <li>
            Se o dano for proveniente de casos fortuitos ou de força maior.
          </li>
        </ul>
      </section>
      <section>
        <h4>11. SIGILO DAS INFORMAÇÕES:</h4>
        <p>
          Para garantir a segurança do Usuário, todas as informações
          compartilhadas com a STARTA são mantidas sob sigilo e
          confidencialidade, nos termos do que prevê a Lei Geral de Proteção de
          Dados (Lei 13.709/2018).
        </p>
        <p>
          Para o desenvolvimento de Projetos e Soluções inovadoras na área da
          Tecnologia, a STARTA garante o sigilo, o dever de
          confidencialidade e a não divulgação das informações confidenciais.
        </p>
      </section>
      <section>
        <h4>12. MEDIDAS DE SEGURANÇA:</h4>
        <p>
          Com o objetivo de garantir a confidencialidade, a integridade e a
          disponibilidade dos dados e dos sistemas de informação utilizados, a
          STARTA adota as medidas técnicas, organizacionais e
          administrativas para proteger os dados pessoais de acessos não
          autorizados e de situações acidentais ou ilícitas de destruição,
          perda, alteração, comunicação ou difusão, riscos e ataques
          cibernéticos, conforme disposições da Lei Geral de Proteção de Dados
          Pessoais (Lei no 13.709/18).
        </p>
        <p>
          Dessa forma, a STARTA adota os melhores esforços para preservar a
          privacidade dos dados de usuários, de acordo com a tecnologia
          disponível atualmente. Entretanto, não se pode assegurar que os
          recursos empregados são totalmente seguros, especialmente aqueles por
          meio dos quais é possível a violação e acessos não autorizados,
          realizados através de métodos e artifícios de engenharia social
          desenvolvidos para obter informações de forma indevida.
        </p>
        <p>
          Por isso, a STARTA orienta que seus Usuários também adotem as
          medidas apropriadas e que lhe são cabíveis para se proteger de
          violações indevidas e não autorizadas, como a instalação de softwares
          de segurança, preservação de dados de acesso e senhas.
        </p>
        <p>
          A STARTA se compromete a comunicar a Autoridade Nacional e o
          Titular sobre a ocorrência de incidentes de segurança que possam
          acarretar risco ou dano relevante aos titulares, de acordo com os
          prazos e regulamentos legais.
        </p>
      </section>
      <section>
        <h4>13. INCIDENTE DE SEGURANÇA:</h4>
        <p>
          Em caso de risco cibernético ou incidente de segurança, resultando
          possível dano ou prejuízo ao tratamento e armazenamento dos dados e
          informações de Colaboradores, Clientes, Parceiros Comerciais e
          Prestadores de Serviços, a STARTA comunicará a Autoridade
          Nacional de Proteção de Dados, no prazo de 2 (dois) dias úteis,
          contados da data do conhecimento do fato, sobre:
        </p>
        <ul>
          <li>A natureza dos dados pessoais afetados;</li>
          <li>Informações sobre os titulares envolvidos;</li>
          <li>
            Quais as medidas técnicas e de segurança serão utilizadas para a
            proteção dos dados;
          </li>
          <li>O perfil dos riscos relacionados ao incidente;</li>
          <li>
            As medidas a serem adotadas para reverter ou mitigar os efeitos do
            incidente de segurança.
          </li>
        </ul>
        <p>
          O Usuário também será comunicado, em forma de alerta de incidente,
          sobre os fatos ocorridos.
        </p>
      </section>
      <section>
        <h4>14. COMO UTILIZAMOS OS COOKIES:</h4>
        <p>
          Cookies são pequenos dados enviados por um servidor web para o seu
          navegador e armazenado no seu computador ou dispositivo pessoal. Para
          oferecer melhor experiência no uso de nossos serviços web e
          aplicações, poderemos usar cookies de sessão (que expiram quando você
          fecha o navegador) e cookies persistentes (que permanecem no seu
          computador até que você os delete). Este tipo de informação é coletado
          para tornar o serviço mais útil e oferecer uma experiência mais
          conveniente e valiosa para você.
        </p>
        <p>
          Usamos duas categorias de cookies: Cookies servidos diretamente por
          nós ao seu computador ou dispositivo para que possamos reconhecê-lo
          quando você retorna ao nosso site e cookies de terceiros, usados para
          estatísticas de acesso, segurança e disponibilidade do site. Você pode
          fazer a gestão dos cookies diretamente no seu navegador.
        </p>
      </section>
      <section>
        <h4>15. LEGISLAÇÃO APLICÁVEL</h4>
        Esta Política será regida, interpretada e executada de acordo com as
        Leis vigentes na República Federativa do Brasil, em especial, as
        disposições da Lei no 13.709/2018 (LGPD) e GDPR - General Data
        Protection Regulation.
      </section>
      <section>
        <h4>16. ENTIDADE RESPONSÁVEL PELO TRATAMENTO DE DADOS:</h4>
        <p>
          A entidade responsável pelo tratamento dos dados é a{" "}
          <b> STARTA TECNOLOGIA DA INFORMAÇÃO LTDA. (STARTA)</b>,
          pessoa jurídica de direito privado, inscrita no CNPJ sob o no
          16.713.376/0001-83.
        </p>
      </section>
      <section>
        <h4>17. ENCARREGADO DE PROTEÇÃO DE DADOS:</h4>
        <p>Nome: Diógenes Dorneles da Rosa</p>
        <p>E-mail: jony@startaideia.com.br</p>
      </section>
      <section>
        <p>Data da última atualização: outubro de 2023.</p>
      </section>
      <section>
        <h4>GLOSSÁRIO</h4>
        <p>Nesta Política, os termos têm os seguintes significados:</p>
        <p>
          • Leis de Proteção de Dados: são todas as Leis Aplicáveis relacionadas
          com o Processamento de Dados Pessoais.
        </p>
        <p>
          • Dado Pessoal: toda a informação relativa às pessoas, que possa ser
          identificada direta ou indiretamente, por meio de informações como,
          por exemplo: nome, CPF, RG, número de identificação do trabalhador,
          identificação por meios eletrônicos (e-mail, IP, smartphones),
          filiação.
        </p>
        <p>
          • Tratamento de Dados: toda operação realizada com dados pessoais,
          como as que se referem a coleta, produção, recepção, classificação,
          utilização, acesso, reprodução, transmissão, distribuição,
          processamento, arquivamento, armazenamento, eliminação, avaliação ou
          controle da informação, modificação, comunicação, transferência,
          difusão ou extração;
        </p>
        <p>
          • Transferência Internacional de Dados: é o procedimento pelo qual o
          Agente/Interveniente das operações de exportação e importação de
          mercadorias processa, armazena e transfere dados e informações
          pessoais entre diferentes países.
        </p>
        <p>
          • Titular: pessoa natural a quem se referem os dados pessoais que são
          objeto de tratamento;
        </p>
        <p>
          • Controlador: pessoa natural ou jurídica, de direito público ou
          privado, a quem competem as decisões referentes ao tratamento de dados
          pessoais;
        </p>
        <p>
          • Operador: pessoa natural ou jurídica, de direito público ou privado,
          que realiza o tratamento de dados pessoais em nome do controlador;
        </p>
        <p>
          • Encarregado: pessoa indicada pelo controlador e operador para atuar
          como canal de comunicação com os titulares dos dados e a Autoridade
          Nacional de Proteção de Dados (ANPD);
        </p>
        <p>
          • Consentimento: manifestação livre, informada e inequívoca pela qual
          o titular concorda com o tratamento de seus dados pessoais para uma
          finalidade determinada;
        </p>
        <p>
          • Eliminação: exclusão de dado ou de conjunto de dados armazenados em
          banco de dados, independentemente do procedimento empregado;
        </p>
        <p>
          • GDPR: General Data Protection Regulation (Regulamento Geral sobre a
          Proteção de Dados), é o conjunto de normas e regulamentação das
          práticas de uso de dados no ambiente digital criado pelo Parlamento
          Europeu e Conselho da União Europeia.
        </p>
        <p>
          • Autoridade Nacional de Proteção de Dados (ANPD): é órgão da
          administração pública responsável por zelar, implementar e fiscalizar
          o cumprimento da Lei Geral de Proteção de Dados. Prestadores de
          Serviços: São pessoas físicas ou jurídicas, sem vínculo empregatício,
          contratadas pela Starta para desenvolver uma atividade ou
          realizar projetos, mediante remuneração.
        </p>
        <p>
          • Parceiros Comerciais: São todas as partes interessadas que têm algum
          tipo de interesse, influência ou impacto nas atividades e resultados
          da nossa organização. Isso inclui clientes, parceiros, funcionários,
          fornecedores ou outros grupos que podem afetar ou serem afetados pelas
          operações da empresa.
        </p>
      </section>
    </div>
  );
}

export default PT;
