export const items = [
  {
    title:
      "Quanto tempo o profissional pode ficar alocado através do Outsourcing?",
    content:
      "Não há um limite de tempo de alocação dos profissionais, o contrato de alocação pode ser de seis meses a um ano e o mesmo pode ser prorrogado por tempo indeterminado.",
  },
  {
    title: "Até quantos profissionais podem compor uma Squad Ágil?",
    content:
      "A quantidade de profissionais e suas especialidades serão definidas a partir da demanda do seu desafio, e de acordo com a sua necessidade. Não há uma quantidade determinada, mas sempre há o cuidado de compor uma equipe completa e eficiente para que tenhamos entregas ágeis.",
  },
  {
    title:
      "Quais são as especialidades profissionais que encontro através das Soluções do Outsourcing e Squads Ágeis da Starta?",
    content:
      "Nossa equipe conta com profissionais altamente qualificados das diversas áreas do desenvolvimento de software, análise de qualidade, design, UX/UI, gestão de projetos, agilidade, marketing e outros.",
  },
];
