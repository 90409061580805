import Image from "../../../assets/winwithus.webp";
import { items } from "./contants";
import styles from "./styles.module.scss";

function WinWithUs() {
  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <h3 className={styles.title}>Ganhe mais com a Starta</h3>
        <div className={styles.flex}>
          <div className={styles.content}>
            {items.map((item) => (
              <div className={styles.item} key={item.title}>
                <div className={styles.icon}>{item.icon}</div>
                <div className={styles.wrapper}>
                  <h4 className={styles.subtitle}>{item.title}</h4>
                  <p className={styles.text}>{item.text}</p>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.image}>
            <img src={Image} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default WinWithUs;
