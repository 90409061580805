function EN() {
  return (
    <div>
      <h2>SECURITY INCIDENT RESPONSE PLAN</h2>
      <h3>
        Read below our framework with plans and actions related to security
        incidents and data privacy.
      </h3>

      <section>
        <h4>What is a security incident?</h4>
        <p>
          Starta defines a security incident in it’s online services as a
          confirmed violation which leads to the accidental or illegal total
          destruction, loss, alteration, non-authorized sharing or access to
          customers and personal data during the processing by Starta.
        </p>
        <p>
          For example, unauthorized access to Starta’s infrastructure of
          online services and customers data leak will constitute a security
          incident, while conformity events that doesn’t affect confidentiality,
          integrity or disponibility of services or customers data are not
          considered security incidents.
        </p>
      </section>

      <section>
        <h4>How does Starta respond to security incidents?</h4>
        <p>
          Whenever there is a security incident, Starta strives to respond
          in a fast and efficient way to protect both Starta’s services and
          customer data. Starta employs an incident response strategy
          designed to investigate, contain, and swiftly remove security threats
          with efficiency.
        </p>
        <p>
          Starta's cloud services are continuously monitored for signs of
          compromise. In addition to automated security monitoring and alerts,
          all employees undergo annual training to recognize and report signs of
          potential security incidents. Any suspicious activity detected by
          employees, customers, or security monitoring tools is forwarded to the
          Service-specific Security Response teams for investigation. All
          service operations teams, including the specific service security
          response teams, maintain a deep on-call rotation to ensure resources
          are available for incident response 24/7, 365 days a year. Our on-call
          rotations enable Starta to mount an effective incident response
          at any time or scale, including widespread or simultaneous events.
        </p>
        <p>
          When suspicious activity is detected and escalated, the
          Service-specific Security Response teams initiate a process of
          analysis, containment, eradication, and recovery. These teams
          coordinate the analysis of the potential incident to determine its
          scope, including any impact on customers or customer data. Based on
          this analysis, the service-specific security response teams work with
          the affected service teams to develop a plan to contain the threat and
          minimize the impact of the incident, eradicate the threat from the
          environment, and fully recover to a known safe state. The relevant
          service teams implement the plan with support from the
          service-specific security response teams to ensure the threat is
          successfully eliminated, and the affected services undergo a complete
          recovery.
        </p>
        <p>
          After an incident is resolved, service teams implement lessons learned
          from the incident to better prevent, detect, and respond to similar
          incidents in the future. Selected security incidents, especially those
          affecting the customer or resulting in a data breach, undergo a
          complete post-mortem. The post-mortem is designed to identify
          technical lapses, procedural failures, manual errors, and other
          process failures that may have contributed to the incident or were
          identified during the incident response process. Improvements
          identified during the post-mortem are implemented with coordination
          from the service-specific security response teams to help prevent
          future incidents and enhance detection and response capabilities.
        </p>
      </section>
      <section>
        <h4>Contact</h4>
        <p>
          To report any security and data privacy incidents, please use our{" "}
          <a href="https://startaideia.com.br/suporte">Support</a>.
        </p>
      </section>
    </div>
  );
}

export default EN;
